<template>
  <div id="app">
    <loading ref="loading" />

    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
  </div>
</template>

<script>
import Loading from './Loading.vue'
import DarkModeMixin from '@showjoe/ffui/src/mixins/dark-mode.vue'
// Load layout components dynamically.
const layoutsImport = import.meta.glob('../layouts/**/*.vue')
const layouts = resolveLayouts(layoutsImport)
/**
 * @param  {Object} modules
 * @return {Object}
 */
function resolveLayouts (modules) {
  return Object.keys(modules)
    .map(file =>
    [file.replace('../layouts/', '').replace(/(^.\/)|(\.vue$)/g, ''), modules[file]]
  )
  .reduce((components, [name, component]) => {
    components[name] = component.default || component
    return components
  }, {})
}

export default {
  el: '#app',

  components: {
    Loading
  },
  mixins:[DarkModeMixin],
  data: () => ({
    layout: null,
    defaultLayout: 'default',
    devMode: false,
    hmr: import.meta.hot
  }),

  metaInfo () {
    const { appName } = window.config

    return {
      title: appName,
      titleTemplate: `%s · ${appName}`
    }
  },

  mounted () {
    this.$loading = this.$refs.loading
    this.devMode = window.config.env == 'local'
  },

  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout (layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout
      }

      this.layout = layouts[layout]
    }
  }
}
</script>
