import axios from 'axios'

// state
export const state = {
  loading: false,
  updating: false,
  users: [],
  user: {},
  newUserErrors: {},
  table: [],
  tables: [],
}


// getters
export const getters = {
  tableList: state => state.tableList,
  tableData: state => state.table,
  relatedData: state => state.tables
}
// mutations
export const mutations = {
  GET_USERS(state, response) {
    // console.log(response) 
    state.users = response
  },
  GET_USER_DATA(state, response) {
    // console.log(response) 
    state.user = response
  },
  GET_TABLE_DATA(state, data) {
    if (Array.isArray(data)) {
      state.table = data[0];
      state.tables = data.slice(1, data.length)
    } else {
      state.table = data
    }
    state[data.state] = false;

  },
  SAVE_USER(state, response) {
    // console.log('SAVE_USER') 
  },
  UPDATE_USER(state, response) {
    // console.log('UPDATE_USER') 
  },
  EXPORT_DATA(state, data) {
    // console.log(state, data) 
  }
}

// actions
export const actions = {
  saveNotes({ commit, state }, payload) {
    return axios.post('/admin/notes', payload)
      .then((resp) => {
        commit('patients/GET_PATIENT_OVERVIEW_DATA', resp.data, { root: true })
        return resp
      });
  },
  getTableRecordData({ commit, state }, params) {
    state.loading = true
    console.log(params)
    return axios.get('/admin/table/record', { params })
      .then((resp) => {
        commit('GET_TABLE_DATA', resp.data)
        return resp
      });
  },
  getUsers({ commit, state }, params) {
    state.loading = true
    return axios.get('/admin/user')
      .then((resp) => {
        commit('GET_USERS', resp.data)
        return resp
      });
  },
  getUserData({ commit, state }, params) {
    state.loading = true
    return axios.get('/admin/user/' + params.user_id)
      .then((resp) => {
        commit('GET_USER_DATA', resp.data)
        return resp
      });
  },
  saveUser({ commit, state }, user) {
    state.loading = true
    return axios.post('/admin/user/', user)
      .then((resp) => {
        commit('SAVE_USER', resp.data)
        return resp
      });
  },
  updateUser({ commit, state }, user) {
    state.loading = true
    return axios.put('/admin/user/' + user.id, user)
      .then((resp) => {
        commit('UPDATE_USER', resp.data)
        return resp
      });
  }
}
export const namespaced = true;
