import store from '~/store'
import { findIndex } from 'lodash'
import Cookies from 'js-cookie'

/**
 * This is middleware to check the current user role.
 *
 * middleware: 'role:admin,manager',
 */

export default (to, from, next, roles) => {
  if (!store.getters['auth/check']) {
    // console.log('not auth')
    Cookies.set('intended_url', to.path)
    next({ name: 'login' })
  } else {

    // Grab the user
    const user = store.getters['auth/user']

    // Split roles into an array
    roles = roles.split(',')
    var hasRequiredRole = findIndex(user.roles, (r) => roles.includes(r.name)) > -1
    // Check if the user has one of the required roles...
    if (!hasRequiredRole) {
      next({ name: '404' })
    }

    next()
  }
}
