<template>
  <card title="Common Errors:" head :body="false">
    <template #header-right>
      <!-- chevron icon shows and hides common errors -->
      <btn @click.native="show_common_errors = !show_common_errors" outline class="py-0">
        <fa icon="chevron-down" :rotation="show_common_errors ? 180 : null" style="transition: transform .3s ease;">
        </fa>
      </btn>
    </template>
    <collapse :show="show_common_errors">
      <list-group flush>
        <list-group-item v-for="(common_error, index) in commonErrors" @click.native="setFocussedError(common_error)"
          :key="common_error.dataitem_name" :class="{ 'border-primary': isFocussedError(common_error) }">
          <div class="d-flex p-1">
            <div class="me-2">
              <badge class="p-1">{{ index + 1 }}</badge>
            </div>
            <div v-if="isFocussedError(common_error)" class="flex-grow-1">
              <select v-model="commonErrors[index].dataitem_name" class="form-control">
                <option v-for="di in other_items" :value="di.name" v-html="di.label"></option>
                <optgroup v-for="section in sections" :label="section.title">
                  <option v-for="di in section.fields" :value="di.name" v-html="di.label"></option>
                </optgroup>
              </select>
            </div>
            <div v-else class="form-control border-0 dropdown_alt flex-grow-1"
              v-html="dataitems[common_error.dataitem_name].label"></div>
            <dropdown class="mx-2" :items="getLookup(common_error.dataitem_name)"
              v-model="commonErrors[index].error_value">
            </dropdown>
            <button type="button" class="btn-close ms-auto" aria-label="Close"
              @click="deleteCommonError(common_error)"></button>
          </div>
          <alert v-if="recordHasItemSelected(common_error) && common_error.error_value == 1" class="mt-2 p-2">This item
            is
            scored on the current record and therefore should not be set to 'selected'</alert>
          <alert v-if="!recordHasItemSelected(common_error) && common_error.error_value == -1" class="mt-2 p-2">This
            item
            is
            NOT scored on the current record and therefore should not be set to 'unselected'</alert>
          <div class="d-flex mt-2 ms-3">
            <textarea class="form-control" rows="3" style="height: auto; " v-model="common_error.message" v-resize
              placeholder="Feedback"></textarea>
          </div>
        </list-group-item>
        <list-group-item v-if="isFocussedError(new_common_error) && !bvcase.locked">
          <div class="d-flex">
            <div class="flex-grow-1">
              <select v-model="new_common_error.dataitem_name" class="form-control">
                <option v-for="di in other_items" :value="di.name" v-html="di.label"></option>
                <optgroup v-for="section in sections" :label="section.title">
                  <option v-for="di in section.fields" :value="di.name" v-html="di.label"></option>
                </optgroup>
              </select>
            </div>
            <dropdown class="mx-2" :items="getLookup(new_common_error.dataitem_name)"
              v-model="new_common_error.error_value" :disabled="!new_common_error.dataitem_name">

            </dropdown>
            <button type="button" class="btn-close ms-auto" aria-label="Close" @click="clearNewCommonError()"></button>
          </div>
          </div>
          <alert v-if="recordHasItemSelected(new_common_error) && new_common_error.error_value == 1" class="mt-2 p-2">
            This
            item is scored on the current record and therefore should not be set to 'selected'</alert>
          <alert v-if="!recordHasItemSelected(new_common_error) && new_common_error.error_value == -1" class="mt-2 p-2">
            This
            item is NOT scored on the current record and therefore should not be set to 'selected'</alert>
          <textarea class="form-control mt-2" rows="3" style="height: auto;" v-model="new_common_error.message" v-resize
            placeholder="Feedback"></textarea>
          <div class="d-flex mt-2" v-if="new_common_error.dataitem_name && new_common_error.error_value != null">
            <btn size="sm" @click.native="addToCommonErrors()">
              <fa icon="plus" /> Add this common error
            </btn>
          </div>
        </list-group-item>

      </list-group>
      <div class="card-footer d-flex" v-if="show_common_errors">
        <btn size="sm" @click.native="showNewCommonErrorForm" class="m-auto">
          <fa icon="plus" /> Add a common error
        </btn>
      </div>
    </collapse>

  </card>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { each, clone, indexOf } from 'lodash-es'
export default {
  name: 'common-errors',
  props: {
    caseRef: {
      type: Object,
      default: null
    }
  },
  mounted() {
    if (this.caseRef) {
      this.setSections()
      this.setOtherItems()
    }
  },
  updated() {

  },

  computed: {
    ...mapState('editor', ['bvcase', 'dataitems', 'records']),

    commonErrors() {
      return this.records.common_errors
    }
  },
  newCommonError() {
    return {
      dropdown_focus: false,
      dataitem_name: null,
      error_value: null,
      message: null
    }
  },
  data() {
    return {
      show_common_errors: true,
      sections: {},
      other_items: {},
      current_error: false,
      dropdown_focus: false,
      new_common_error: {}
    }
  },
  methods: {
    isFocussedError(error) {
      return (this.current_error == error)
    },
    recordHasItemSelected(error) {
      return error.dataitem_name &&
        this.records[error.dataitem_name]
    },
    setFocussedError(error) {
      this.current_error = error
    },
    showNewCommonErrorForm() {

      this.clearNewCommonError()
      this.setFocussedError(this.new_common_error)
    },
    setOtherItems() {
      if (this.caseRef)
        this.other_items = this.caseRef.other_items
    },
    setSections() {
      var type = this.bvcase.questionnaire_type;
      var sections = {};
      if (this.caseRef) {
        var groups = this.caseRef.groups;
        var items = this.caseRef[type + '_items']
        each(groups, (group, i) => {
          if (i != 'Ot') sections[i] = { title: group.title, fields: items[i].fields, index: items[i].index }
        });
        this.sections = sections;
      } else
        this.sections = {}
    },
    getDataitem(name) {
      return this.dataitems[name]
    },
    getLookup(name) {
      var dataitem = this.getDataitem(name)
      if (dataitem) {
        if (dataitem.lookup) {
          // clone is not a function
          var clone = Object.assign([], dataitem.lookup.items)
          clone.push({ label: 'Unselected', value: -1 })
          return clone
        } else {
          return [{ label: 'Selected', value: 1 }, { label: 'Unselected', value: -1 }]
        }
      }
    },
    addToCommonErrors() {
      var newError = clone(this.new_common_error)
      this.records.common_errors.push(newError);
      this.clearNewCommonError()
    },
    clearNewCommonError() {
      this.new_common_error = {
        dropdown_focus: false,
        visible: false,
        dataitem_name: null,
        error_value: null,
        message: null
      }
    },
    toggleCommonErrorDropdown(index) {
      this.commonErrors[index].dropdown_focus = !this.commonErrors[index].dropdown_focus
      // var ddf = evt.type == 'blur' && error.dataitem_name != null ? false : true
      // Vue.set(error, 'dropdown_focus', ddf)
    },
    deleteCommonError(error) {
      var ces = this.records.common_errors
      var index = findIndex(ces, function (ce) { return ce == error; });
      if (index > -1) this.records.common_errors.splice(index, 1)
    },
  },
  watch: {
    caseRef(newVal, oldVal) {
      this.setSections()
      this.setOtherItems()
    }
  }
}

</script>
