import Vue from 'vue'
import { subscribeToMediaQuery, convertBreakpointsToMediaQueries, transformValuesFromBreakpoints } from 'vue-mq/src/helpers.js'
import VueMq from 'vue-mq'
Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    xs: 575,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400,
    xxl: 1401
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})
// Vue.filter('mq', (currentBreakpoint, values) => {
//   return transformValuesFromBreakpoints(Object.keys(breakpoints), values, currentBreakpoint)
// })
Vue.prototype.$filters = Vue.options.filters