<template>
  <list-group-item>
    <div class="d-flex">
      <div class="fw-bold">
        {{ collection.id }}: {{ collection.name }}: {{ collection.title }}
      </div>
      <router-link class="ms-auto" v-if="showEdit"
        :to="{ name: 'edit_collection', params: { collection_id: collection.id } }">
        <fa icon="chevron-circle-right" size="2x"></fa>
      </router-link>
    </div>
    <div class="d-flex">
      <div class="case_list">
        <transition-group name="fade" tag="div" class="case_list_inner">
          <button v-for="bvcase in collection.cases" :key="bvcase.id" :case-id="bvcase.id"
            :class="['case_icon', { 'missing': !getCase(bvcase.id) }]" v-tooltip="{ text: bvcase.name }"></button>
        </transition-group>
      </div>
      <div class="reserve_case_list" v-if="collection.reserve_cases.length">
        <transition-group name="fade" tag="div" class="case_list_inner">
          <button v-for="bvcase in collection.reserve_cases" :key="bvcase.id" :case-id="bvcase.id"
            :class="['case_icon', { 'missing': !getCase(bvcase.id) }]" v-tooltip="{ text: bvcase.name }"></button>
        </transition-group>
      </div>
    </div>
  </list-group-item>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  props: {
    collection: Object,
    showEdit: Boolean,
  },
  computed: {
    ...mapState('editor', ['cases', 'loaded']),

  },
  methods: {
    getCase(id) {
      return this.cases.find((c) => id == c.id);
    },
  }
}

</script>
