<template>
  <card title="Case Review" head :body="false">
    <div class="row m-1">
      <div class="col">
        <fieldset class="d-flex flex-column h-100">
          <legend>
            Review 1
          </legend>
          <div class="d-flex mb-2" v-if="bvcase.reviewer1">
            <img :src="bvcase.reviewer1.photo_url" class="rounded-circle profile-photo me-1" style="height: 1.5rem;"
              :title="bvcase.reviewer1.name">
            <div class="flex-grow-1">{{ bvcase.reviewer1.name }}</div>
            <div>{{ formatDate(bvcase.reviewer1_at) }}</div>
          </div>

          <form-group class="flex-grow-1">
            <textarea placeholder="Notes" class="form-control p-2" v-model="bvcase.reviewer1_notes" v-resize
              style="resize: none;" :disabled="bvcase.locked"></textarea>
          </form-group>
          <btn class="btn-block" v-if="!bvcase.locked"
            :btn-class="!bvcase.reviewer1_id ? 'outline-primary' : 'outline-warning'"
            :disabled="!bvcase.reviewer1_notes" @click.native="markReview(1)">
            <span v-if="bvcase.reviewer1_id == null">Review Case (as Reviewer 1)</span>
            <span v-else>Update Review (as Reviewer 1)</span>
          </btn>
        </fieldset>
      </div>
      <div class="col" v-if="bvcase.reviewer1_id != null">
        <fieldset class="d-flex flex-column h-100">
          <legend>
            Review 2
          </legend>
          <div class="d-flex mb-2" v-if="bvcase.reviewer2">
            <img :src="bvcase.reviewer2.photo_url" class="rounded-circle profile-photo me-1" style="height: 1.5rem;"
              :title="bvcase.reviewer2.name">
            <div class="flex-grow-1">{{ bvcase.reviewer2.name }}</div>
            <div>{{ formatDate(bvcase.reviewer2_at) }}</div>
          </div>

          <form-group class="flex-grow-1">
            <textarea placeholder="Notes" class="form-control" v-model="bvcase.reviewer2_notes" v-resize
              style="resize: none;" :disabled="bvcase.locked || bvcase.reviewer1_id == user.id"></textarea>
          </form-group>
          <btn class="btn-block" v-if="!bvcase.locked" btn-class="outline-primary"
            :disabled="bvcase.reviewer1_id == user.id || !bvcase.reviewer2_notes" @click.native="markReview(2)">
            <span v-if="bvcase.reviewer1_id != user.id">Review Case (as Reviewer 2)</span>
            <span v-else>Cannot review both</span>
          </btn>
        </fieldset>

      </div>
    </div>
  </card>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'reviewer-panel',
  computed: {
    ...mapState('editor', ['bvcase', 'user']),
    ...mapState('auth', ['user'])
  },
  methods: {
    formatDate(date) {
      return this.$d(new Date(date), 'numeric', 'en-GB')
    },
    markReview(reviewerNumber) {
      if (!this.bvcase['reviewer' + reviewerNumber + '_notes']) return false;
      if (reviewerNumber == 2 && this.bvcase.reviewer1_id == this.user.id) return false;
      this.bvcase['reviewer' + reviewerNumber + '_id'] = this.user.id
      this.bvcase['reviewer' + reviewerNumber + '_at'] =
        new Date().toISOString().slice(0, 19).replace('T', ' ')
      this.$emit('update:case', this.bvcase)
    }
  }
}
</script>