/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from 'vue'
import App from '~/components/App.vue'
import store from '~/store'
import router from '~/router'
import i18n from '~/plugins/i18n'

import '../sass/app.scss'

import '~/plugins'
import '~/components'
import '~/directives'


window.Vue = Vue;


/* eslint-disable no-new */
const mainApp = new Vue({
  i18n,
  store,
  router,
  ...App,
  computed: {
    config: () => window.config,
  }
})

// console.log(mainApp)
if (import.meta.hot) {
  // import.meta.hot.send('my:from-client', { msg: 'Hey!' })
  // import.meta.hot.on('my:greetings', (data) => {
  //   console.log(data.msg) // hello
  // })
}
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js'
// window.Pusher = Pusher;
// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: import.meta.env.VITE_PUSHER_APP_KEY,
//   cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//   // forceTLS: false,
//   forceTLS: false,
//   wsHost: '127.0.0.1',
//   wsPort: 6001,
//   encrypted: false,
//   authorizer: (channel, options) => {
//     return {
//       authorize: (socketId, callback) => {
//         axios.post('broadcasting/auth', {
//           socket_id: socketId,
//           channel_name: channel.name
//         })
//           .then(response => {
//             callback(false, response.data);
//           })
//           .catch(error => {
//             callback(true, error);
//           });
//       }
//     };
//   },
// });

// import.meta.hot.accept((newModule) => {
//   console.log('accept', newModule)
//     if (newModule) {
//       // newModule is undefined when SyntaxError happened
//       console.log('newModule', newModule)
//     }
//   })