import store from '~/store'

export default (to, from, next) => {
  if (!store.getters['auth/isAdmin']) {
    if(from.name) next({ name: from.name })
    	else next('/')
  } else {
    next()
  }
}
