import axios from 'axios'
import Vue from 'vue'
import store from '~/store'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const dateTimeFormats = {
  'en': {
    numeric: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    numeric_datetime: {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
    },
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    },


  },
  'en-GB': {
    numeric: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    numeric_datetime: {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
    },
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  'en-US': {
    numeric: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    numeric_datetime: {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
    },
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  'ja-JP': {
    numeric: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    numeric_datetime: {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
    },
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
    }
  },
  'zh-CN': {
    numeric: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    numeric_datetime: {
      year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
    },
    short: {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
    }
  }
}
var locale = 'en'
var fallbackLocale = 'en'
const loadedLanguages = [] // our default language that is preloaded
const i18n = new VueI18n({
  locale,
  fallbackLocale,
  dateTimeFormats,
  messages: {}
})

window.i18n = i18n
export function setI18nLanguage(lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {

  return import(`../lang/${lang}/index.js`)
    .then((resp) => {
      const { default: messages } = resp
      const LocaleMessages = resolveLang(messages)
      loadedLanguages.push(lang)
      i18n.mergeLocaleMessage(lang, LocaleMessages)
      setI18nLanguage(lang)
      store.dispatch('lang/addMessages', { messages: LocaleMessages, locale: lang, prefix: false })
    })
}

/**
 * @param {String} locale
 */
export async function loadMessages(locale) {
  const path = '../lang/' + locale + '/**/*.json'
  const { default: messages } = await import(`../lang/${locale}/index.js`)
  const LocaleMessages = resolveLang(messages)
  // i18n.mergeLocaleMessage(locale, LocaleMessages)
  store.dispatch('lang/addMessages', { messages: LocaleMessages, locale })

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }


}


/**
 * @param  {Object} modules
 * @return {Object}
 */
function resolveLang(modules) {
  return Object.keys(modules)
    .map(file =>
      [file.replace('./', '').replace(/(^.\/)|(\.json$)/g, ''), modules[file]]
    )
    .reduce((components, [name, component]) => {
      var nameArr = name.split('/')
      if (nameArr.length > 1) {
        if (!components[nameArr[0]]) components[nameArr[0]] = {}
        components[nameArr[0]][nameArr[1]] = component.default || component
      } else {
        components[name] = component.default || component
      }
      return components
    }, {})
}

; (async function () {
  await loadLanguageAsync(locale)


})()


export default i18n


