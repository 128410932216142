

<script>
import CandidateCase from "./CandidateCase.vue";
export default {
    name: "CandidateInitialAssessment",
    extends: CandidateCase,
    data() {
        return {

        }
    },
    methods: {
        save() {
            if (this.isCandidate) {
                this.saveCandidateCaseData({ bvcase: this.bvcase, records: this.records, initial_assessment: true })
                    .then(() => {
                        this.$gtag.config({ user_id: this.user.id });
                        this.$gtag.event('save', {
                            'event_category': 'CandidateCase',
                            'event_label': 'candidate case saved',
                            'value': this.case_id
                        })
                    })
            }

        },
    },
    mounted() {

    }
}
</script>

<style lang="scss" scoped></style>