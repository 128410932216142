// import { makeQuestionnaireRoutes } from './questionnaires.js'
const pageImport = import.meta.globEager('../pages/**/*.vue')
const pages = {}
for (const path in pageImport) {
    var mod = pageImport[path]
    var newPath = path.replace('../pages/', '')
    pages[newPath] = mod.default
}
function page(path) {
    return pages[path]
}
// const adminQuestionnaires = makeQuestionnaireRoutes({ name_prefix: 'admin.' })
// const participantQuestionnaires = makeQuestionnaireRoutes({ middleware: 'role:Participant' })
// const namedCollectionRoutes = { adminQuestionnaires, participantQuestionnaires}
// Group routes from json
const routeJson = import.meta.globEager('./routes/*.json')
const routes = []
var allRoutes = []
const routeGroups = {}

for (const path in routeJson) {
    var routeGroup = routeJson[path]
    var newPath = path.replace('./routes/', '').replace(/(^.\/)|(\.json$)/g, '')
    allRoutes = allRoutes.concat(routeGroup.default)
    routeGroups[newPath] = routeGroup.default
}
// Add standard routes
routes.push(findRoute('welcome'))
routes.push(findRoute('login'))
routes.push(findRoute('register'))
routes.push(findRoute('registration.complete'))
routes.push(findRoute('password.request'))
routes.push(findRoute('password.reset'))
routes.push(findRoute('verification.verify'))
routes.push(findRoute('verification.resend'))

routes.push(findRoute('staff_home'))
routes.push(findRoute('admin_tools'))
routes.push(findRoute('admin_staff'))

routes.push(findRoute('cases'))
routes.push(findRoute('new_case'))
routes.push(findRoute('edit_case'))
routes.push(findRoute('edit_training_case'))

routes.push(findRoute('training_sessions'))
routes.push(findRoute('edit_training_session'))
routes.push(findRoute('new_training_session'))

routes.push(findRoute('candidates'))
routes.push(findRoute('candidates.training_session'))
// routes.push(findRoute('candidate'))
// routes.push(findRoute('candidate_case'))
routes.push(findRoute('candidate_training_case'))

routes.push(findRoute('collections'))
routes.push(findRoute('new_collection'))
routes.push(findRoute('edit_collection'))

routes.push(findRoute('candidates_home'))
routes.push(findRoute('candidates_case'))
routes.push(findRoute('candidates_training_case'))
routes.push(findRoute('initial_assessment'))
routes.push(findRoute('learning_resources'))




// routes.push(findRoute('home'))

// routes.push(findRoute('admin.dashboard'))
// routes.push(findRoute('admin.sites'))
// routes.push(findRoute('admin.patients'))
// routes.push(findRoute('admin.patient_overview'))
// routes.push(findRoute('admin.patient_issue_overview'))

// routes.push(findRoute('admin.questionnaires'))
// routes.push(findRoute('admin.questionnaire'))

routeGroups.wrappers.forEach((wrapper) => {
    if (wrapper.name) wrapper = findRoute(wrapper.name)
    else wrapper.component = page(wrapper.component_path)
    if (wrapper.children) {
        if (typeof wrapper.children == 'object') wrapper.children = findRoutesFromArray(wrapper.children)
        if (typeof wrapper.children == 'string') wrapper.children = findRoutesFromNamedCollection(wrapper.children)
    }
    routes.push(wrapper)
})

// routes.push(findRoute('404'))

export function findRoute(name) {
    if (name) {
        var rootConfig = allRoutes.find((r) => r.name == name)
        rootConfig.component = page(rootConfig.component_path)
        rootConfig.props = props
        return rootConfig
    }
}
export function findRoutesFromArray(arr) {

    return arr.map((a) => {
        return findRoute(a)
    })
}
export function findRoutesFromNamedCollection(name) {
    return namedCollectionRoutes[name]
}

export function props(route) {
    var name = route.meta && route.meta.name ? route.meta.name : route.name
    return { name, ...route.params }
}

export default routes