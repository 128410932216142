import Vue from 'vue'
import Editor from '@tinymce/tinymce-vue'

// Default icons are required for TinyMCE 5.3 or above
// import 'tinymce/icons/default';

// A theme is also required
// import 'tinymce/themes/silver';

// Any plugins you want to use has to be imported
// import 'tinymce/plugins/paste';
// import 'tinymce/plugins/link';
Vue.component('editor', Editor)

// import 'tinymce/skins/ui/oxide/skin.min.css';

// Initialize the app
// tinymce.init({
//   selector: '#tiny',
//   plugins: ['paste', 'link']
// });