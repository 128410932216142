import { mapActions, mapState, mapGetters } from 'vuex'
import { find, filter, includes, map, } from 'lodash'
export default {
  data() {
    return {
      isResetting: false
    }
  },
  methods: {
    ...mapActions('editor', ['replaceCandidateCase', 'resetCandidateCase']),
    getCollection(collection_id) {
      return find(this.collections, (coll) => {
        return coll.id == collection_id
      })
    },
    getCollectionReserveCases(collection) {
      if (!collection) return []
      if (this.getCollection(collection.collection_id)) {
        var CaseCollection = this.getCollection(collection.collection_id)
        var replacedCases = this.replacedCases(collection)
        var usedReservesInCollection = this.usedReservesInCollection(collection)
        var candidatesUsedReserves = this.candidatesUsedReserves(this.candidate)
        // diff the two arrays
        var usedInOtherCollections = candidatesUsedReserves.filter((c) => { return !includes(usedReservesInCollection, c) })
        var reserves = CaseCollection.reserve_cases.map((c) => {
          if (includes(usedReservesInCollection, c.id)) {
            var ccase = find(replacedCases, (rc) => { return rc.json.replaced_with == c.id })
            if (ccase) c.replaced = ccase

          }
          return c
        })
          .filter((c) => { return !includes(usedInOtherCollections, c.id) })
        return reserves
      }
      return [];
    },
    collectionHasReserveCases(collection) {
      return this.getCollectionReserveCases(collection).length > 0
    },
    usedReservesInCollection(collection) {
      return map(this.replacedCases(collection), (c) => { if (c.json && c.json.replaced_with) return c.json.replaced_with });
    },
    candidatesUsedReserves(candidate) {
      var usedReserves = []
      if (candidate.collections)
        candidate.collections.forEach(collection => {
          var collectionReserves = this.usedReservesInCollection(collection)
          usedReserves = usedReserves.concat(collectionReserves)
        });
      if (this.candidate_collections)
        this.candidate_collections.forEach(collection => {
          var collectionReserves = this.usedReservesInCollection(collection)
          usedReserves = usedReserves.concat(collectionReserves)
        });
      return usedReserves
    },
    collectionComplete(collection) {
      return filter(this.cases(collection), (c) => { return (c.score == null) }).length == 0;
    },
    cases(collection) {
      return filter(collection.cases, (c) => { return !c.json || (c.json && !c.json.replaced) });
    },
    getCandidateCase(case_id, collection) {
      var myCCase = collection.cases.find((c) => { return c.case_id == case_id })
      if (myCCase) return myCCase
    },
    replacedCases(collection) {
      var coll = filter(collection.cases, (c) => { return (c.json && c.json.replaced == true) });
      return coll
    },
    getBVCaseName(ccase, collection) {
      var bvc = this.getBVCase(ccase, collection)
      if (bvc) return bvc.name;
      return ''
    },
    getBVCase(ccase, collection) {
      var coll = find(this.collections, (c) => { return c.id == collection.collection_id; })
      var bvcase = find(coll.cases, (c) => {
        return c.id == ccase.case_id;
      })
      if (!bvcase) {
        bvcase = find(coll.reserve_cases, (c) => { return c.id == ccase.case_id; })
      }
      if (!bvcase && ccase.name) bvcase = ccase
      return bvcase
    },
    swapWithReserveCase(ccase) {
      var self = this
      var replacement = this.selectedReserveCase

      this.replaceCandidateCase({ case: ccase, replacement })
        .then(() => {
          var params = self.$route.params;
          params.case_id = replacement.id
          if (self.$route.name == 'candidate_case') self.$router.push({ name: 'candidate_case', params })
        })
      this.selectedReserveCase = false
    },
    resetCase(ccase) {
      this.isResetting = true
      this.resetCandidateCase({ case: ccase })
        .then(() => {
          this.isResetting = false
        });
    }
  }
}