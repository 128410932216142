import axios from 'axios'
import Cookies from 'js-cookie'
import { findIndex } from 'lodash'
// state
export const state = {
  user: null,
  token: Cookies.get('token')
}

// getters
export const getters = {
  user: state => state.user,
  token: state => state.token,
  check: state => state.user !== null,
  isCandidate: state => {
    if (state.user && state.user.roles) return findIndex(state.user.roles, ['name', 'Candidate']) > -1
    else return false
  },
  isParticipant: state => {
    if (state.user && state.user.roles) return findIndex(state.user.roles, ['name', 'Candidate']) > -1
    else return false
  },
  isEditor: state => {
    if (state.user && state.user.roles) return findIndex(state.user.roles, ['name', 'Editor']) > -1
    else return false
  },
  isTrainer: state => {
    if (state.user && state.user.roles) return findIndex(state.user.roles, ['name', 'Trainer']) > -1
    else return false
  },
  isStaff: (state, getters) => {
    if (state.user && state.user.roles) return getters.isTrainer || getters.isEditor || getters.isAdmin
    else return false
  },
  isTeam: (state, getters) => {
    if (state.user && state.user.roles) return getters.isTrainer || getters.isEditor || getters.isAdmin
    else return false
  },
  isAdmin: state => {
    if (state.user && state.user.roles)
      return findIndex(state.user.roles, (o) => ['Admin', 'SuperAdmin', 'ConsoleAdmin'].includes(o.name)) > -1
    else return false
  },
}

// mutations
export const mutations = {
  ['SAVE_TOKEN'](state, { token, remember }) {
    state.token = token
    Cookies.set('token', token, { expires: remember ? 365 : null })
  },

  ['FETCH_USER_SUCCESS'](state, { user }) {
    state.user = user
  },

  ['FETCH_USER_FAILURE'](state) {
    state.token = null
    Cookies.remove('token')
  },

  ['LOGOUT'](state) {
    state.user = null
    state.token = null
    // window.Echo.leave('ActiveUsers')
    Cookies.remove('token')
  },

  ['UPDATE_USER'](state, { user }) {
    state.user = user
  },
}

// actions
export const actions = {
  saveToken({ commit }, payload) {
    commit('SAVE_TOKEN', payload)
  },

  async fetchUser({ commit, dispatch }) {
    try {
      const { data } = await axios.get('/user')
      commit('FETCH_USER_SUCCESS', { user: data })
      // window.Echo.join('ActiveUsers')

    } catch (e) {
      commit('FETCH_USER_FAILURE')
    }
  },
  updateUser({ commit }, payload) {
    commit('UPDATE_USER', payload)
  },

  async logout({ commit }) {
    try {
      await axios.post('/logout')
    } catch (e) { }

    commit('LOGOUT')
  },

  // async fetchOauthUrl(ctx, { provider }) {
  //   const { data } = await axios.post(`/oauth/${provider}`)

  //   return data.url
  // }
}
export const namespaced = true;
