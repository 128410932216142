<template>
  <div :class="{ 'container-xxl': !fullscreen }" style="transition:all .3s ease;">

    <transition name="fade">
      <div v-if="bvcase && records && loaded">
        <row class="mb-4 g-1">
          <column :sizes="{ xl: 4, xxl: 4 }">
            <card :title="bvcase.name" head class="mb-2">
              <version-controls ref="version-controls" v-if="locked" :bvcase="bvcase"
                :bvcase_latest="bvcase_latest"></version-controls>
              <router-link slot="header-left" :to="{ name: 'cases' }"
                style="margin: -8px 8px 0 -8px; vertical-align: middle;" alt="Back to Cases">
                <fa icon="chevron-circle-left" size="2x"></fa>
              </router-link>
              <div slot="header-right">
                <span style="vertical-align: text-bottom;">
                  Version:
                  <badge v-text="version.version + '/' + bvcase_latest.version"></badge>
                </span>
                <fa :icon="locked ? 'lock' : 'unlock'" size="2x" class="mx-2" @click="bvcase.locked = !bvcase.locked">
                </fa>
                <btn outline @click.native="fullscreen = !fullscreen" class="ms-2">
                  <fa :icon="fullscreen ? 'arrows-left-right-to-line' : 'arrows-left-right'"></fa>
                </btn>
              </div>
              <form-group cols="4|8" bp="xxl" :di="{ name: 'case_name', label: 'Name:' }" class="mb-4">
                <textbox name="name" v-model="bvcase.name" :readonly="locked" autocomplete="0"></textbox>
              </form-group>
              <form-group cols="4|8" bp="xxl" :di="{ name: 'diagnosis', label: 'Diagnosis:' }" class="mb-4">
                <div v-if="!locked && bvcase.diagnosis_dropdown_focus || !bvcase.diagnosis">
                  <select name="diagnosis" v-model="bvcase.diagnosis" class="form-control" :readonly="locked" v-focus
                    @blur="toggleDiagnosisDropdown($event)">
                    <option v-for="diagnosis in diagnoses" :value="diagnosis.value" v-html="diagnosis.label"></option>
                  </select>
                </div>
                <div v-else class="dropdown_alt" @click="toggleDiagnosisDropdown($event)"
                  v-html="diagnosis(bvcase.diagnosis).label">
                </div>
              </form-group>
              <form-group cols="4|8" bp="xxl" :di="{ name: 'case_type', label: 'Case Type:' }" class="mb-4">
                <btn-group type="radio" :justified="true">
                  <btn v-model="bvcase.case_type" btn-class="primary" :outline="true" true-value="training"
                    :readonly="locked" class="w-100">Training</btn>
                  <btn v-model="bvcase.case_type" btn-class="primary" :outline="true" true-value="assessment"
                    :readonly="locked" class="w-100">Assessment</btn>
                </btn-group>
              </form-group>
              <form-group cols="4|8" bp="xxl" :di="{ name: 'questionnaire_type', label: 'Questionnaire Type:' }"
                class="mb-4">
                <btn-group type="radio" :justified="true">
                  <btn @click.native="changeQuestionnaireType('bvas')" btn-class="primary" :outline="true"
                    :readonly="locked" :class="['w-100', { 'active': bvcase.questionnaire_type == 'bvas' }]">BVAS</btn>
                  <btn @click.native="changeQuestionnaireType('vdi')" btn-class="primary" :outline="true"
                    :readonly="locked" :class="['w-100', { 'active': bvcase.questionnaire_type == 'vdi' }]">VDI</btn>
                  <btn @click.native="changeQuestionnaireType('pvas')" btn-class="primary" :outline="true"
                    :readonly="locked" :class="['w-100', { 'active': bvcase.questionnaire_type == 'pvas' }]">PVAS</btn>
                  <btn @click.native="changeQuestionnaireType('pvdi')" btn-class="primary" :outline="true"
                    :readonly="locked" :class="['w-100', { 'active': bvcase.questionnaire_type == 'pvdi' }]">PVDI</btn>
                </btn-group>
              </form-group>
              <form-group cols="4|8" bp="xxl" :di="{ name: 'case_difficulty', label: 'Difficulty:' }"
                style="min-height:80px;" class="mb-4">
                <btn-group type="radio" :justified="true" :readonly="locked">
                  <btn v-model="bvcase.difficulty" type="radio" v-for="diff in ['Bronze', 'Silver', 'Gold']" :key="diff"
                    :true-value="diff" :readonly="locked"
                    :class="['w-100', 'btn-medal', { 'medal-active': bvcase.difficulty == diff }]">
                    <div :class="'medal medal-' + diff.toLowerCase()"></div>
                    <br> {{ diff }}
                  </btn>
                </btn-group>
              </form-group>
              <form-group v-if="!isTrainingCase">
                <label>Question:</label>
                <prosemirror-editor v-if="!locked && !ui.versionControls.showChanges && isLatestVersion"
                  v-model="bvcase.question"></prosemirror-editor>
                <div v-else class="version-textbox">
                  <div v-if="ui.versionControls.showChanges && version.diff" v-html="version.diff" class="diffed-html">
                  </div>
                  <div v-else v-html="version.question"></div>
                </div>
              </form-group>
              <form-group v-if="isTrainingCase">
                <label>Training Sections:</label>
                <div class="btn-group">
                  <router-link v-for="(tcase, i) in bvcase.training_cases" :key="i"
                    :to="{ name: 'edit_training_case', params: { case_id: case_id, stage: i + 1 } }"
                    class="btn btn-outline-primary">
                    {{ i + 1 }}
                  </router-link>
                  <div class="btn btn-default" @click="addStage">
                    <fa icon="plus"></fa>
                  </div>
                </div>
              </form-group>
              <form-group v-if="isTrainingCase && currentStageSelected">
                <label>Question:</label>
                <prosemirror-editor v-if="!locked && !ui.versionControls.showChanges && isLatestVersion"
                  v-model="currentStage.question">
                </prosemirror-editor>
              </form-group>
              <form-group>
                <label>Case Status: <small>Admin only</small></label>
                <btn-group justified>
                  <btn v-model="bvcase.case_status" btn-class="primary" outline :true-value="1" :readonly="!isAdmin"
                    class="w-100">Active </btn>
                  <btn v-model="bvcase.case_status" btn-class="primary" outline :true-value="2" :readonly="!isAdmin"
                    class="w-100">Archived </btn>
                </btn-group>
              </form-group>
            </card>
          </column>
          <column :sizes="{ xl: 8, xxl: 8 }">
            <component :is="bvcase.questionnaire_type" :ref="bvcase.questionnaire_type"
              :readonly="mode == 'editor' && locked" @hook:mounted="componentMounted">
            </component>
          </column>
        </row>
        <row v-if="bvcase && records && loaded">
          <column :sizes="{ xl: 6 }" v-if="showCommonErrors && $refs && $refs[bvcase.questionnaire_type]">
            <common-errors ref="commonErrors" :case-ref="$refs[bvcase.questionnaire_type]" class="mb-2"></common-errors>
          </column>
          <column :sizes="{ xl: 12 }">
            <reviewer-panel class="mb-2" v-if="bvcase.questionnaire_type && records && records.id"
              @update:case="save"></reviewer-panel>
          </column>
        </row>
        <column>
          <div>
            <btn v-if="isAdmin || !locked" @click.native="save" class="d-flex w-100">
              <transition name="fade-fast">
                <fa icon="save" v-show="saving" class="m-1 me-2"></fa>
              </transition>
              <div class="flex-grow-1">
                SAVE
              </div>
            </btn>
          </div>
        </column>
      </div>
    </transition>
  </div>
</template>
<script>
import versionControls from '~/components/version-controls.vue'
import reviewerPanel from '~/components/reviewer-panel.vue'
import stageMixin from '~/mixins/stage-mixin.js'
import { findIndex } from 'lodash'
import prosemirrorEditor from '~/components/prosemirror-editor.vue'

import commonErrors from '~/components/common-errors.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  layout: 'fullscreen',
  mixins: [stageMixin],
  components: {
    versionControls,
    commonErrors,
    reviewerPanel,
    prosemirrorEditor
  },
  mounted() {
    this.routeChanged()
  },
  data() {
    return {
      fullscreen: false,
      case_id: null,
      showCommonErrors: false,
    }
  },
  watch: {
    // call the method again if the route changes
    '$route': 'routeChanged',
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    locked() {
      return this.bvcase.locked == 1
    },
    ...mapState('editor', ['questionnaires', 'bvcase', 'stage', 'bvcase_latest', 'diagnoses', 'dataitems', 'validation', 'loaded', 'records', 'versions', 'saving', 'mode', 'ui', 'user']),
    version() {
      if (this.$refs['version-controls']) {
        return this.$refs['version-controls'].version
      }
      return this.bvcase
    },
    isLatestVersion() {
      return this.version.version == this.bvcase_latest.version
    },
    canBeReversioned() {
      return (this.isLatestVersion && this.version.locked && this.bvcase.case_status != 1)
    },
  },
  methods: {
    ...mapActions('editor', ['getCaseData', 'saveCaseData', 'setBVCaseData', 'getNewCaseData', 'changeCaseType', 'changeQuestionnaireType', 'incrementVersion', 'updateStage']),
    componentMounted() {
      this.showCommonErrors = true
    },
    routeChanged() {
      var params = this.$route.params
      if (this.$route.name == 'new_case') {
        this.case_id = 'new'
        this.getNewCaseData(this.$route.params);
        return;
      }
      if (!this.case_id) {
        this.getCaseData(params);
        this.case_id = params.case_id
        if (params.stage) this.updateStage(params.stage)
        return;
      }
      if (params.case_id == this.case_id && params.stage != this.stage) {
        this.updateStage(params.stage)
      } else {
        this.getCaseData(params)
        this.case_id = parseInt(params.case_id)
      }
    },
    toggleDiagnosisDropdown(evt) {
      var ddf = evt.type == 'blur' && this.bvcase.diagnosis != null ? false : true
      Vue.set(this.bvcase, 'diagnosis_dropdown_focus', ddf)
    },
    diagnosis(code) {
      var index = findIndex(this.diagnoses, function (diag) { return code == diag.value; });
      if (index > -1) return this.diagnoses[index];
    },
    showVersionControls() {
      if (this.bvcase.version > 1) {
        this.ui.versionControls.visible = !this.ui.versionControls.visible;
      }
    },
    save() {
      if (this.isAdmin || !this.locked)
        this.saveCaseData({ bvcase: this.bvcase, records: this.records })
          .then(() => {
            this.$router.push({ name: 'cases' })
          })
    },
    reversion() {
      this.incrementVersion()
    }
  }
}

</script>
