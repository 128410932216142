<template>
    <transition name="fade" mode="in-out">
        <div>
            <candidate-details v-if="isAdmin || isTrainer"></candidate-details>
            <router-view></router-view>
        </div>
    </transition>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
    middleware: 'role:Admin,Editor,Trainer',
    computed: {
        ...mapState('auth', ['user']),
        ...mapGetters('auth', ['isAdmin', 'isTrainer', 'isEditor']),
    },
}
</script>
