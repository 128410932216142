<template>
    <div>
        <transition name="fade" mode="in-out">
            <card title="Home" head :body="false">
                <list-group flush>
                    <router-link :to="{ name: 'admin_tools' }" class="list-group-item" v-if="isAdmin">
                        <fa icon="toolbox"></fa>
                        <span class="ms-4" v-t="{ path: 'staffHome.admin_tools.title' }"></span>
                    </router-link>
                    <router-link :to="{ name: 'cases' }" class="list-group-item" v-if="isEditor">
                        <fa icon="list-check"></fa>
                        <span class="ms-4" v-t="{ path: 'staffHome.cases.title' }"></span>
                    </router-link>
                    <router-link :to="{ name: 'training_sessions' }" class="list-group-item" v-if="isAdmin">
                        <fa icon="chalkboard-user"></fa>
                        <span class="ms-4" v-t="{ path: 'trainingSession.title' }"></span>
                    </router-link>
                    <router-link :to="{ name: 'candidates' }" class="list-group-item" v-if="isAdmin || isTrainer">
                        <fa icon="users"></fa>
                        <span class="ms-4" v-t="{ path: 'staffHome.candidates' }"></span>
                    </router-link>
                </list-group>
            </card>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
    name: 'StaffHome',
    middleware: 'role:Admin,Editor,Trainer',
    metaInfo() {
        return { title: this.$t('staffHome.title') }
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapGetters('auth', ['isAdmin', 'isTrainer', 'isEditor']),
    },
}
</script>
