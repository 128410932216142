import Vue from "vue";
import VueGtag from "vue-gtag";
import router from '~/router'

const id = import.meta.env.VITE_ANALYTICS_GTAG_ID

const appName = import.meta.env.VITE_APP_NAME
const gTagConfig = {
    config: { id },
    appName,
    pageTrackerScreenviewEnabled: true,
    params: {
        send_page_view: false
    }
}
Vue.use(VueGtag, gTagConfig, router);