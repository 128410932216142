import axios from 'axios'

// state
export const state = {
  centres: {
    list: [],
    loaded: false
  },
}

// getters
export const getters = {
  centres: state => state.centres,
}

// mutations
export const mutations = {
  GET_CENTRES(state, resp) {
    state.centres.list = resp
    state.centres.loaded = true
  }
}

// actions
export const actions = {
  getCentres({ commit, dispatch }, payload) {
    state.loaded = false
    return axios.get('/centres')
      .then((resp) => {
        commit('GET_CENTRES', resp.data)
      });
  }
}
export const namespaced = true;
