import Vue from 'vue'
import componentList from "./components.json";

import { resolveImport } from '../helpers/module-importer.js'

import {
  HasError,
  AlertError,
  AlertSuccess,
} from "vform/components/bootstrap5";

const componentsImport = import.meta.glob("./**/*.vue");
const componentsResolved = resolveImport(componentsImport, './**/', /(^.\/)|(\.vue$)/g)

var path = "./";
componentList.forEach((componentName) => {
  var strBeforeSlash = componentName.replace(/[^/]*$/g, '')
  var cleanName = componentName.replace(strBeforeSlash, '')
  if (["BVAS", "VDI", "PVAS", "PVDI"].includes(cleanName)) {
    cleanName = cleanName.toLowerCase()
  }
  var c = componentsResolved[componentName]
  var Component = c.default || c;

  Vue.component(cleanName, Component);
});
[
  AlertError,
  AlertSuccess,
  HasError
].forEach(Component => {
  Vue.component(Component.name, Component)
})


// import donutProgress from './donut-progress.vue'
// import donut from './donut.vue'
// import commonErrors from './common-errors.vue'
// import reviewerPanel from './reviewer-panel.vue'
// import stageProgress from './stage-progress.vue'
// import versionControls from './version-controls.vue'
// import fileInput from './file-input.vue'
// import scope from './scope.vue'
// import bvas from './BVAS.vue'
// import pvas from './PVAS.vue'
// import vdi from './VDI.vue'
// import pvdi from './PVDI.vue'
// import candidateDetails from './candidate-details.vue'
// import fileUpload from './file-upload'
// Components that are registered globaly.
// [
//   Child,
//   commonErrors,
//   reviewerPanel,
//   stageProgress,
//   versionControls,
//   donutProgress,
//   donut,
//   fileInput,
//   scope,
//   candidateDetails,
//   HasError, AlertError, AlertSuccess
// ].forEach(Component => {
//   Vue.component(Component.name, Component)
// })


// Vue.component('bvas', bvas);
// Vue.component('pvas', pvas);
// Vue.component('vdi', vdi);
// Vue.component('pvdi', pvdi);