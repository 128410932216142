<template>
  <div :class="{ 'container-xxl': !fullscreen }" style="transition:all .3s ease;">
    <card head foot :body="false">
      <template #header>
        <div class="card-header d-flex align-items-center">

          <h4 v-if="filters.training_session_id"
            v-t="{ path: 'candidates.candidates_title_filtered', args: { name: filteredTrainingSessionName } }"></h4>
          <h4 v-else v-t="'candidates.candidates_title'"></h4>
          <fa v-if="loadingCandidates" icon="cog" spin size="2x" class="m-auto"></fa>
          <btn v-model="showFilterRow" :btn-class="$root.theme" class="ms-auto">
            <fa icon="filter" />
          </btn>
          <div class="flex-shrink-1 ms-1">
            <dropdown v-model="filters.training_session_id" :items="availableTrainingSessions" value-name="id"
              label-name="title" btn-outline class="input-group" @input="setFilters"
              :btn-label="$t('candidates.training_session_dropdown_label')">
              <!-- <template #list="{ item }">
            <router-link :class="['dropdown-item']"
              :to="{ name: 'candidates.training_session', params: { training_session_id: item.id } }">{{ item.title
              }}</router-link>
          </template> -->
              <btn @click.native="filters.training_session_id = null; setFilters()" outline class="rounded-end">
                <fa icon="users"></fa>
              </btn>
            </dropdown>
          </div>
          <btn outline @click.native="fullscreen = !fullscreen" class="ms-2">
            <fa :icon="fullscreen ? 'arrows-left-right-to-line' : 'arrows-left-right'"></fa>
          </btn>
        </div>
      </template>
      <div class="header dt-row p-1" v-if="showFilterRow">
        <div class="row">
          <form-group :di="{ name: 'users.name', label: 'Name' }" cols="3|9" :sizes="{ md: 4 }" class="mb-0">
            <input-group>
              <textbox type="name" required v-model="filters['users.name']"></textbox>
              <template #append v-if="filters['users.name']">
                <btn @click.native="makeRequest()">Search</btn>
                <btn btn-class="secondary" @click.native="filters['users.name'] = null; makeRequest()">Clear</btn>
              </template>
            </input-group>
          </form-group>
          <form-group :di="{ name: 'users.email', label: 'Email' }" cols="3|9" :sizes="{ md: 4 }" class="mb-0">
            <input-group>
              <textbox type="email" required v-model="filters['users.email']"></textbox>
              <template #append v-if="filters['users.email']">
                <btn @click.native="makeRequest()">Search</btn>
                <btn btn-class="secondary" @click.native="filters['users.email'] = null; makeRequest()">Clear</btn>
              </template>
            </input-group>
          </form-group>
        </div>
      </div>
      <data-table class="candidate-table" :columns="columns" :data="candidates" hide-index>
        <template #header-column="{ col }">
          <div v-if="col.sortable" @click="setSorting(col.name)"
            :class="['dt-column dt-column-header dt-column-' + col.name, sortingClass(col)]">
            <div class="p-2" v-text="col.label" />
          </div>
          <div v-else :class="['dt-column dt-column-header dt-column-' + col.name + '']">
            <div class="p-2" v-text="col.label" />
          </div>
        </template>
        <template #cell-name="{ rowData, col }">
          <div class="p-2" v-text="rowData.name" />
        </template>
        <template #cell-email="{ rowData, col }">
          <div class="p-2" v-text="rowData.email" />
        </template>
        <template #column-initial_assessment="{ rowData }">
          <scope v-if="rowData.initial_assessment && rowData.initial_assessment.length"
            :cia="rowData.initial_assessment[0]" v-slot="{ cia }" class="dt-column dt-column-initial_assessment">
            <router-link class="align-self-center"
              :to="{ name: 'admin.initial_assessment', params: { candidate_id: rowData.id, case_id: cia.case_id } }">
              <donut label="" :percent="cia ? cia.pivot.score : null" :inner-radius="20" :outer-radius="24"
                :percent-pass="50" :showStartIcon="true" :enlarge-when-updated="true" style="max-width:50px" />
            </router-link>
          </scope>
          <div v-else class="dt-column dt-column-initial_assessment p-1"></div>
        </template>
        <template #column-archived="{ rowData }">
          <div class="dt-column dt-column-archived p-1">
            <div v-if="rowData.archivedTrainingSessions.length" v-popover="{
              event: 'hover',
              title: 'Archived Training Sessions:',
              html: archiveStatus(rowData.archivedTrainingSessions),
            }">
              <fa icon="check" class="ms-1"></fa>
            </div>
          </div>
        </template>

        <template #column-training_session="{ rowData }">
          <scope v-if="rowData.initial_assessment && rowData.initial_assessment.length"
            :cia="rowData.initial_assessment[0]" v-slot="{ cia }"
            class="dt-column dt-column-training_session d-flex p-1">
            <template v-for="coll in rowData.collections">
              <scope :tsc="getTrainingSessionCollection(rowData.training_session_id, coll.collection_id)"
                v-slot="{ tsc }" tag="template" style="display: contents;">
                <div
                  v-if="(tsc.pivot.meta.IA_Pass && cia.pivot.score > 50) || (!tsc.pivot.meta.IA_Pass && cia.pivot.score < 50)"
                  class="case-dot-collection"
                  v-popover="{ event: 'hover', title: 'collection:', body: getCollectionName(coll) }">
                  <div v-for="ccase in coll.cases"
                    :class="['case-dot', { 'bg-success': ccase.score != null && ccase.score >= 50 }, { 'bg-danger': ccase.score != null && ccase.score < 50 }]">

                  </div>
                </div>
              </scope>
            </template>
          </scope>
          <div v-else class="dt-column dt-column-training_session d-flex p-1">
            <div v-for="coll in rowData.collections" class="case-dot-collection"
              v-popover="{ event: 'hover', title: 'collection:', body: getCollectionName(coll) }">
              <div v-for="ccase in coll.cases"
                :class="['case-dot', { 'bg-success': ccase.score != null && ccase.score >= 50 }, { 'bg-danger': ccase.score != null && ccase.score < 50 }]">

              </div>
            </div>
          </div>
          <!-- <badge :key="coll.id" v-for="coll in rowData.collections" class="m-1">{{coll.score ? coll.score : '___'}}</badge> -->
        </template>
        <template #cell-updated_at="{ rowData, col }">
          <div class="p-2 fs-6"><small> {{ $d(new Date(rowData.updated_at), 'numeric_datetime', 'en-GB') }}</small>
          </div>
        </template>
        <template #column-edit="{ rowData }">
          <div class="dt-column dt-column-edit p-1">
            <btn outline @click.native="showEditUserModal(rowData)" block size="sm">
              <fa icon="cog" size="lg"></fa>
            </btn>
          </div>
        </template>
        <template #column-view="{ rowData }">
          <div class="dt-column dt-column-view p-1">
            <btn outline is-router-link :to="{ name: 'candidate', params: { candidate_id: rowData.id } }" block
              size="sm">
              <fa icon="chevron-right" size="lg"></fa>
            </btn>
          </div>
        </template>

      </data-table>

      <template #footer-inner>
        <div class="d-flex align-items-middle">
          <btn outline :disabled="loadingCandidates" @click.native="showNewUserModal" class="me-auto"
            v-t="'candidates.add_candidate'"></btn>

          <ul class="pagination mb-0">
            <li v-for="link in pagination.links"
              :class="['page-item', { active: link.active, disabled: !link.url || loadingCandidates }]">
              <a @click="updatePagination(link)" class="page-link" v-html="link.label" />
            </li>
          </ul>
          <dropdown :disabled="loadingCandidates" btn-outline v-model="pagination.per_page"
            :items="paginationPerPageList" class="m-auto" @input="updatePerPage">
            <template #btn-inner="{ chosenLabel }">{{ chosenLabel }} {{ $t('pagination.per_page') }}</template>
          </dropdown>
        </div>
      </template>
    </card>
    <modal :show="newUserModalShow" size="lg" :title="$t('candidates.new', { name: newUser.name })">
      <div :class="{ 'was-validated': newUserValidated }">
        <form-group :di="{ name: 'name', label: 'Name:' }" cols="3|9" :error="newUserErrors.name">
          <textbox v-model="newUser.name" />
        </form-group>
        <form-group :di="{ name: 'email', label: 'Email:' }" cols="3|9"
          :error="newUserErrors.email || newUserErrors.email_hash">
          <textbox type="email" v-model="newUser.email" />
        </form-group>
        <form-group :di="{ name: 'training_session_id', label: 'Training Session:' }" cols="3|9"
          :error="newUserErrors.training_session_id">
          <dropdown split justified :items="training_sessions" label-name="title" value-name="id"
            v-model="newUser.training_session_id">
            <template #list-item-append="{ item }">
              : {{ item.name }}
            </template>
          </dropdown>
        </form-group>
      </div>
      <template #footer-inner="{ close, save }">
        <btn btn-class="secondary" class="ms-auto" @click.native="close(newUser)">
          <fa icon="times"></fa> {{ $t('trainingSession.cancel') }}
        </btn>
        <btn btn-class="success" @click.native="createUser(newUser)">
          <fa icon="save"></fa> {{ $t('trainingSession.save') }}
        </btn>
      </template>
    </modal>
    <modal :show="editCandidateModalShow" size="lg" :title="$t('candidates.edit', { name: editedCandidate.name })"
      @save="updateUser(editedCandidate)" @close="editCandidateModalShow = false">
      <div :class="{ 'was-validated': editedCandidateValidated }">
        <form-group :di="{ name: 'name', label: 'Name:' }" cols="3|9" :error="editedCandidateErrors.name">
          <textbox v-model="editedCandidate.name" />
        </form-group>
        <form-group :di="{ name: 'email', label: 'Email:' }" cols="3|9"
          :error="editedCandidateErrors.email || editedCandidateErrors.email_hash">
          <textbox type="email" v-model="editedCandidate.email" />
        </form-group>
        <form-group :di="{ name: 'training_session_id', label: $t('trainingSession.title') }" cols="3|9"
          :error="editedCandidateErrors.training_session_id">
          <dropdown split justified :items="training_sessions" label-name="title" value-name="id"
            v-model="editedCandidate.training_session_id">
            <template #list-item-append="{ item }">
              : {{ item.name }}
            </template>
          </dropdown>

          <!-- <btn class="mt-2" btn-class="secondary"
            @click.native="assignToTrainingSession(editedCandidate.training_session_id)"
            v-t="'candidates.reassign_cases'">
          </btn> -->
        </form-group>
        <div v-for="coll in editedCandidate.collections" class="case-dot-collection"
          v-popover="{ event: 'hover', title: 'collection:', body: getCollectionName(coll) }">
          <div v-for="ccase in coll.cases"
            :class="['case-dot', { 'bg-success': ccase.score != null && ccase.score >= 50 }, { 'bg-danger': ccase.score != null && ccase.score < 50 }]">
            <!-- {{ccase.score}} -->
          </div>
        </div>
      </div>
      <template #footer-inner="{ close, save }">
        <btn btn-class="danger" class="me-auto" @click.native="deleteCandidateConfirm(editedCandidate)">
          <fa icon="trash"></fa> {{ $t('trainingSession.delete') }}
        </btn>
        <btn btn-class="secondary" class="ms-auto" @click.native="close(editedCandidate)">
          <fa icon="times"></fa> {{ $t('trainingSession.cancel') }}
        </btn>
        <btn btn-class="success" @click.native="updateUser(editedCandidate)">
          <fa icon="save"></fa> {{ $t('trainingSession.save') }}
        </btn>
      </template>
    </modal>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import { filter, find, findIndex, forEach, map, flatten, clone } from 'lodash'
import paginationMixin from '~/mixins/pagination'

export default {
  layout: 'fullscreen',
  middleware: 'role:Admin,Trainer',
  mixins: [paginationMixin],
  apiMethod: 'getCandidates',
  metaInfo() {
    return { title: this.$t('candidates.candidates_title') }
  },
  watch: {
    'filters.training_session_id'() {
      var params = { page: 1 }
      this.makeRequest(params);
    },
  },
  mounted() {
    this.makeRequest();
  },
  data() {
    var filtersEmpty = {
      'users.name': null,
      'users.email': null,
      'training_session_id': null
    }
    return {
      fullscreen: false,
      editedCandidate: {},
      editedCandidateErrors: false,
      editedCandidateValidated: false,
      newUser: {},
      newUserErrors: false,
      newUserValidated: false,
      newUserModalShow: false,
      editCandidateModalShow: false,
      showFilterRow: false,
      filters: this.getLocalStorage('filters') ? JSON.parse(this.getLocalStorage('filters')) : filtersEmpty,
      params: {
        timestamps: true,
        include: 'user,collections,initial_assessment,archivedTrainingSessions',
        page: 1,
        // append: 'issues',
        sort: this.getLocalStorage('sort') ? this.getLocalStorage('sort') : '-updated_at',
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isTrainer', 'isAdmin']),
    // ...mapState('editor', ['training_sessions', 'loadingCandidates']),
    ...mapState('resources/candidate', ['candidates', 'pagination', 'training_sessions', 'loadingCandidates']),
    // ...mapState('editor', {
    //   ui: state => state.ui.candidates
    // }),
    columns() {
      return [
        { name: "id", label: this.$t('candidates.candidates_columns.id'), type: Number, sortable: true, width: 'min-content' },
        { name: "user_id", label: this.$t('candidates.candidates_columns.user_id'), type: Number, sortable: true, width: 'max-content' },
        { name: "name", label: this.$t('candidates.candidates_columns.name'), type: Number, sortable: true, width: 'max-content' },
        { name: "email", label: this.$t('candidates.candidates_columns.email'), type: Number, width: 'min-content' },
        { name: "initial_assessment", label: this.$t('candidates.candidates_columns.ia'), type: Number, width: 'min-content' },
        { name: "archived", label: this.$t('candidates.candidates_columns.archived'), type: Number, width: '1fr' },

        { name: "training_session", label: this.$t('candidates.candidates_columns.training_session'), type: Number },
        { name: "updated_at", label: this.$t('candidates.candidates_columns.updated_at'), type: Date, sortable: true, width: 'max-content' },
        { name: "edit", label: this.$t('candidates.candidates_columns.edit'), type: Number, width: 'min-content' },
        { name: "view", label: this.$t('candidates.candidates_columns.view'), type: Number, width: 'min-content' }
      ]
    },
    filteredTrainingSessionName() {
      if (this.filters.training_session_id)
        var ts = this.training_sessions.find((ts) => {
          return ts.id == this.filters.training_session_id
        })
      if (ts) return ts.title
    },
    // paginationQuery() {
    //   var tsid = this.$route.params.training_session_id
    //   if (!tsid) { }
    //   return {
    //     params: {
    //       grouped: this.ui.grouped,
    //       training_session_id: this.$route.params.training_session_id,
    //     }
    //   }
    // },
    // paginationOffset() {
    //   var p = this.ui.pagination
    //   return (p.current - 1) * p.per_page
    // },
    availableTrainingSessions() {
      if (this.isAdmin) return this.training_sessions;
      if (this.isTrainer) return this.user.training_sessions;
    },
  },
  methods: {
    // ...mapActions('editor', ['getCandidates', 'clearCandidateData', 'addNewCandidate', 'updateCandidate', 'assignCandidateToTrainingSession', 'deleteCandidate']),
    ...mapActions('resources/candidate', ['getCandidates', 'clearCandidateData', 'addNewCandidate', 'updateCandidate', 'assignCandidateToTrainingSession', 'deleteCandidate']),
    // setupCandidateListQuery() {
    //   if (this.isTrainer) {
    //     var id = this.$route.params.training_session_id ? this.$route.params.training_session_id : this.paginationQuery.params.training_session_id
    //     if (this.availableTrainingSessions.length && findIndex(this.availableTrainingSessions, (ts) => ts.id == id) == -1) {
    //       this.paginationQuery.params.training_session_id = this.availableTrainingSessions[0].id
    //       this.$set(this.ui, 'training_session_id', this.availableTrainingSessions[0].id)
    //     } else {
    //       this.paginationQuery.params.training_session_id = id
    //     }

    //   }
    // },
    setFilters() {
      this.setLocalStorage('filters', JSON.stringify(this.filters))
    },
    getCandidatesTrainingSession(candidate) {
      return this.training_sessions.find((ts) => {
        return ts.id == candidate.training_session_id
      })
    },
    getTrainingSessionCollection(training_session_id, collection_id) {
      return this.training_sessions.find((ts) => {
        return ts.id == training_session_id
      }).collections.find((c) => c.id == collection_id)
    },
    getCollectionName(coll) {
      var test = map(this.training_sessions, 'collections')
      test = flatten(test)
      var found = find(test, (t) => {
        return t.id == coll.collection_id
      })
      if (found) return found.title
      return 'test'
    },
    showEditUserModal(user) {
      this.editedCandidate = clone(user)
      this.editedCandidateErrors = {}
      this.editCandidateModalShow = true
    },
    showNewUserModal() {
      this.newUser = { name: '', email: '', training_session_id: this.filters.training_session_id }
      this.newUserErrors = {}
      this.newUserModalShow = true
    },
    assignToTrainingSession() {
      this.assignCandidateToTrainingSession(this.editedCandidate)
    },
    createUser(user) {
      this.addNewCandidate(user)
        .then(resp => {
          this.newUserErrors = false
          this.newUserModalShow = false
          this.getCandidateList()
          this.$gtag.event('createUser', {
            'event_category': 'Candidates',
            'event_label': 'created a new candidate & user',
            'value': user.name
          })
        })
        .catch(err => {
          this.newUserErrors = err.response.data.errors
        })
        .then(resp => {
          this.newUserValidated = true

        })
    },
    updateUser(user) {
      this.updateCandidate(user)

        .then(resp => {
          this.editedCandidateErrors = false
          this.editCandidateModalShow = false
        })
        .catch(err => {
          this.editedCandidateErrors = err.response.data.errors
        })
        .then(resp => {
          this.editedCandidateValidated = true
        })
    },
    deleteCandidateMethod(user) {
      this.deleteCandidate(user)
        .then(resp => {
          this.editedCandidateErrors = false
          this.editCandidateModalShow = false
          this.getCandidateList()
          this.$gtag.event('deleteCandidate', {
            'event_category': 'Candidates',
            'event_label': 'deleted a candidate',
            'value': user.id
          })
        })
        .catch(err => {
          this.editedCandidateErrors = err.response.data.errors
        })
        .then(resp => {
          this.editedCandidateValidated = true
        })
    },
    deleteCandidateConfirm(user) {
      Swal.fire({
        icon: 'warning',
        showCancelButton: true,
        title: this.$t('staffHome.admin_tools.deleteUserConfirmTitle'),
        text: this.$t('staffHome.admin_tools.deleteUserConfirmText'),
        confirmButtonText: this.$t('ok'),
        cancelButtonText: this.$t('cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteCandidateMethod(user)
        }
      })
    },
    // prevPage() {
    //   if (this.ui.pagination.current > 1) this.ui.pagination.current--
    // },
    // nextPage() {
    //   if (this.ui.pagination.current < this.ui.pagination.total_pages) this.ui.pagination.current++
    // },
    // sortByColumn(col) {
    //   if (this.ui.pagination.sort_by == col) {
    //     this.ui.pagination.sort_dir = this.ui.pagination.sort_dir * -1
    //   }
    //   this.ui.pagination.sort_by = col
    //   this.sorted_candidates;
    // },
    group(training_session_id) {
      return find(this.training_sessions, function (g) { return g.id == training_session_id; });
    },
    getCandidateList() {
      this.makeRequest(this.params)
      this.$gtag.event('getCandidates', {
        'event_category': 'Candidates',
        'event_label': 'query candidates list',
        'value': JSON.stringify(this.params)
      })
    },
    archiveStatus(archivedTrainingSessions) {
      var self = this
      if (archivedTrainingSessions.length) {
        var str = ''
        archivedTrainingSessions.forEach(function (ats) {
          str = '<div class="">' +
            '<div>' + self.$t('candidates.archived.collections_archived.training_session', { title: ats.name }) + '</div>'
            + '<div> ' + self.$t('candidates.archived.collections_archived.status', { status: ats.pivot.json.status }) + '</div> '
            + '<div>' + self.$t('candidates.archived.collections_archived.reason', { reason: ats.pivot.json.reason }) + '</div>'
            + '<div>' + self.$t('candidates.archived.collections_archived.archived_at', { archived_at: self.$d(new Date(ats.pivot.json.archived_at), 'short', 'en-GB') }) + '</div>'
            + '</div>'
        })
        return str

      }
    }
  }
}

</script>
