<template>
  <div :class="{ 'container-xxl': $route.name == 'training_sessions' }" style="transition:all .3s ease;">
    <row>
      <column :sizes="{ xl: column2Showing ? 8 : 12, md: 'auto' }" key="column1">
        <card :title="$t('trainingSession.title')" head foot :body="false">
          <data-table class="training-session-table" :columns="trainingSessionTableColumns" :data="training_sessions"
            hide-index>
            <template #column-initial_assessment="{ rowData, col }">
              <div class="dt-column dt-column-id d-flex p-1" v-if="rowData.meta && rowData.meta.has_initial_assessment">
                {{ rowData.meta.has_initial_assessment }}
              </div>
            </template>
            <template #column-collections="{ rowData, col }">
              <div class="dt-column dt-column-collections d-flex p-1">
                <div v-for="coll in rowData.collections" class="case-dot-collection"
                  v-popover="{ event: 'hover', title: 'collection:', body: coll.name }">
                  <div v-for="ccase in coll.cases" :class="['case-dot']">
                    <!-- {{ccase.score}} -->
                  </div>
                </div>
              </div>
            </template>
            <template #column-edit="{ rowData, col }">
              <div class="dt-column dt-column-edit d-flex p-1">
                <btn is-router-link exact outline
                  :to="{ name: 'edit_training_session', params: { training_session_id: rowData.id } }"
                  class="m-auto rounded-0">
                  <fa icon="cog" size="lg"></fa>
                </btn>
              </div>
            </template>
          </data-table>
          <template #footer-inner>
            <div class="d-flex">
              <btn is-router-link exact :to="{ name: 'new_training_session' }" class="ms-auto">
                <fa icon="plus"></fa> {{ $t('trainingSession.add') }}
              </btn>
            </div>
          </template>
        </card>
      </column>
      <transition name="slide-in" mode="out-in">
        <column :sizes="{ xl: 4, md: 'auto' }" key="column2"
          v-if="($route.name == 'edit_training_session' || $route.name == 'new_training_session') && loaded">
          <card v-if="trainingSessionEdit" head foot :title="editTSCardTitle"
            @save-changes="updateTS(trainingSessionEdit)">
            <router-link :class="['btn btn-close']" slot="header-right" :to="{ name: 'training_sessions' }"
              aria-label="Close">
              <span aria-hidden="true"></span>
            </router-link>
            <form-group :di="{ name: 'name', label: 'Name:' }" floating-label :error="trainingSessionEditErrors.name">
              <textbox v-model="trainingSessionEdit.name" />
            </form-group>
            <form-group :di="{ name: 'title', label: 'Title:' }" floating-label :error="trainingSessionEditErrors.title">
              <textbox v-model="trainingSessionEdit.title" />
            </form-group>
            <form-group :di="{ name: 'description', label: 'Description:' }"
              :error="trainingSessionEditErrors.description">
              <prosemirror-editor v-model="trainingSessionEdit.description">
              </prosemirror-editor>
            </form-group>
            <form-group :di="{ name: 'invite_token', label: 'Invite Token:' }" floating-label
              :error="trainingSessionEditErrors.invite_token">
              <textbox v-model="trainingSessionEdit.invite_token" />
            </form-group>

            <form-group :di="{ name: 'has_initial_assessment', label: 'Initial Assessment' }" hide-label
              :error="trainingSessionEditErrors.has_initial_assessment" v-slot="{ di }">
              <checkbox switch v-model="trainingSessionEdit.meta.has_initial_assessment" :label="di.label"
                :true-value="true" />
            </form-group>
            <collapse :show="trainingSessionEdit.meta.has_initial_assessment" v-if="trainingSessionEdit.meta">
              <form-group v-if="trainingSessionEdit.initial_assessment"
                :di="{ name: 'initial_assessment_case', label: 'Case ID:' }"
                :error="trainingSessionEditErrors.has_initial_assessment" v-slot="{ di }">
                <textbox v-model="trainingSessionEdit.initial_assessment.case_id" :true-value="true" />
              </form-group>
              <div
                v-if="trainingSessionEdit.initial_assessment && trainingSessionEdit.initial_assessment.bv_case && trainingSessionEdit.initial_assessment.case_id == trainingSessionEdit.initial_assessment.bv_case.id">
                {{ trainingSessionEdit.initial_assessment.bv_case.name }}
              </div>
              <hr>
            </collapse>
            <form-group :di="{ name: 'collections', label: 'Collections:' }" :error="trainingSessionEditErrors">
              <template v-for="coll in trainingSessionEdit.collections">
                <div class="list-group-item p-1">
                  <div class="d-flex">
                    <div>
                      {{ coll.name }}
                    </div>

                    <div @click="removeCollectionFromTrainingSession(collection.id)" :class="['btn btn-close ms-auto']"
                      aria-label="Close"><span aria-hidden="true"></span></div>
                  </div>
                  <form-group v-if="trainingSessionEdit.meta.has_initial_assessment && coll.pivot.meta"
                    :di="{ name: 'IA_Pass', label: 'Visible if IA Pass' }" hide-label
                    :error="trainingSessionEditErrors.IA_Pass" v-slot="{ di }">
                    <checkbox switch v-model="coll.pivot.meta.IA_Pass" :label="di.label" :true-value="true" />
                  </form-group>
                  <div class="case-dot-collection" v-popover="{ event: 'hover', title: coll.title, body: coll.name }">
                    <div v-for="ccase in coll.cases" :class="['case-dot']">
                      <!-- {{ccase.score}} -->
                    </div>
                  </div>
                </div>
              </template>
              <input-group justified class="my-2">
                <dropdown btn-outline :items="filteredCollections" label-name="name" value-name="id" label="Choose..."
                  v-model="trainingSessionEdit.collectionToAdd" btn-class="primary w-100" class="flex-grow-1 ">
                </dropdown>
                <btn outline slot="append"
                  @click.native="AddCollectionToTrainingSession(trainingSessionEdit.collectionToAdd)">
                  {{ $t('trainingSession.add') }}</btn>
              </input-group>
            </form-group>
            <checkbox :label="'Archived'" :true-value="1" v-model="trainingSessionEdit.archived"
              :errors="trainingSessionEditErrors.archived" />
            <checkbox v-if="trainingSessionEdit.meta" :label="'Show Resources'" :true-value="1"
              v-model="trainingSessionEdit.meta.show_resources" :error="trainingSessionEditErrors.show_resources" />
            <template #footer-inner>
              <btn v-if="$route.name == 'edit_training_session'" btn-class="danger" class="float-start"
                @click.native="deleteTS(trainingSessionEdit)">
                <fa icon="trash" />
                {{ $t('trainingSession.delete') }}
              </btn>
              <btn btn-class="success" class="float-end" @click.native="saveTS(trainingSessionEdit)">
                <fa icon="save" /> {{ $t('trainingSession.save') }}
              </btn>
            </template>
          </card>
        </column>
      </transition>
    </row>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { map, filter, includes, find, clone, findIndex, forEach } from 'lodash'
import prosemirrorEditor from '~/components/prosemirror-editor.vue'

export default {
  layout: 'fullscreen',
  components: {
    prosemirrorEditor
  },
  watch: {
    '$route': {
      handler() {
        this.getTrainingSessions()
          .then(() => {
            if (this.$route.name == 'new_training_session') this.setupNewTrainingSession()
            if (this.$route.name == 'edit_training_session') this.editTrainingSession()
          })
        this.getCaseCollections()
      },
      immediate: true
    },
  },
  training_session: {
    name: '',
    title: '',
    description: '',
    invite_token: null,
    candidates: [],
    collections: [],
    meta: { show_resources: false }
  },
  data() {
    return {
      trainingSessionTableColumns: [
        { name: 'id', label: 'ID', type: String, width: 'min-content' },
        { name: 'name', label: 'Name', type: String, width: '1fr' },
        { name: 'title', label: 'Title', type: String, width: '1fr' },
        { name: 'invite_token', label: 'Invite Token', type: String, width: 'min-content' },
        { name: 'initial_assessment', label: 'Initial Assessment', type: String, width: 'max-content' },
        { name: 'collections', label: 'Collections', type: String },
        { name: 'edit', label: 'Edit', type: String, width: 'min-content' },
      ],
      trainingSessionEdit: {},
      trainingSessionEditErrors: [],
      tinymce_settings: {
        browser_spellcheck: true,
        min_height: 600,
        skin_url: '/css',
        inline: true,
        branding: false,
        block_formats: "Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;"
      }
    }
  },
  computed: {
    ...mapState('editor', ['user', 'training_sessions', 'collections', 'loaded']),
    editTSCardTitle() {
      if (!this.trainingSessionEdit) return;
      if (this.trainingSessionEdit.id) return this.$t('trainingSession.edit', { name: this.trainingSessionEdit.name })
      if (this.trainingSessionEdit) return this.$t('trainingSession.create', { name: this.trainingSessionEdit.name })
    },
    filteredCollections() {
      if (!this.trainingSessionEdit) return;
      var collectionIds = map(this.trainingSessionEdit.collections, (c) => c.id)
      return filter(this.collections, (c) => {
        return !includes(collectionIds, c.id)
      })
    },
    column2Showing() {
      return (this.$route.name == 'edit_training_session' || this.$route.name == 'new_training_session')
    }
  },
  methods: {
    ...mapActions('editor', ['getTrainingSessions', 'saveTrainingSession', 'deleteTrainingSession', 'getCaseCollections', 'update']),
    setupNewTrainingSession() {
      this.trainingSessionEdit = clone(this.$options.training_session)
      this.trainingSessionEditErrors = []
      //   this.$refs.newTrainingSessionModal.showModal()
    },
    editTrainingSession() {
      var id = this.$route.params.training_session_id
      var ts = find(this.training_sessions, (t) => { return t.id == id })
      this.trainingSessionEdit = clone(ts)
      if (ts.description == null) ts.description = ''
      this.trainingSessionEditErrors = []
    },
    AddCollectionToTrainingSession(id) {
      var coll = find(this.collections, (c) => { return c.id == id })
      this.trainingSessionEdit.collections.push(coll)
    },
    removeCollectionFromTrainingSession(id) {
      var index = findIndex(this.trainingSessionEdit.collections, (c) => c.id == id)

      this.trainingSessionEdit.collections.splice(index, 1)
    },
    saveTS(user) {
      this.saveTrainingSession(user)
        .then(() => {
          this.$router.push({ name: 'training_sessions' })
        })
        .catch(error => {
          // console.log(error)
          this.update({ loaded: true })
          this.trainingSessionEditErrors = error.response.data.errors
        });
    },
    deleteTS(user) {
      // console.log('deleteTrainingSession', user)
      this.deleteTrainingSession(user)
        .then(() => {
          this.$router.push({ name: 'training_sessions' })
        })
        .catch(error => {
          this.trainingSessionEditErrors = error.response.data.errors
        });
    },
  }
}

</script>
