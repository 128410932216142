<template>
  <div class="d-flex mx-n2 mb-3 mt-n2 justify-content-between align-items-center">
    <btn outline size="sm" @click.native="versionControls.showChanges = !versionControls.showChanges"
      :class="{ 'active': versionControls.showChanges }" class="me-1">
      Show Changes
    </btn>
    <btn outline size="sm" :disabled="versionControls.currentVersion == 1" @click.native="prevVersion">
      <fa icon="chevron-left"></fa>
    </btn>
    <div class="text-center">
      {{ !versionControls.showChanges || bvcase.version == 1 ? bvcase.version + ' / ' + bvcase_latest.version : '' }}
      {{ versionControls.showChanges && bvcase.version != 1 && version.diff ? bvcase.version - 1 + ' => ' +
        bvcase.version :
        ''
      }}
    </div>
    <btn outline size="sm" :disabled="versionControls.currentVersion == bvcase_latest.version"
      @click.native="nextVersion">
      <fa icon="chevron-right"></fa>
    </btn>
    <btn outline size="sm" v-if="bvcase.locked && bvcase.case_status != 1"
      :disabled="versionControls.currentVersion < bvcase.version" @click.native="reversion">
      RE-VERSION
    </btn>
  </div>
</template>
<script>
import { findIndex } from 'lodash'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'version-controls',
  props: {
    bvcase: Object,
    bvcase_latest: Object,
  },
  computed: {
    ...mapState('editor', {
      versionControls: state => state.ui.versionControls,
      versions: state => state.versions,
    }),
    version() {
      if (this.versions.length > 1) {
        var self = this;
        var index = findIndex(this.versions, function (c) {
          return self.versionControls.currentVersion == c.version;
        });
        if (index > -1) return this.versions[index];
      }
      return this.bvcase
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.versionControls.currentVersion = this.bvcase.version
    })
  },
  watch: {
    version(newVal, oldVal) {
      // console.log('version changed',newVal)
      var bvcase = newVal
      var type = bvcase.questionnaire_type
      var records = bvcase[type]
      // console.log(bvcase,type,records,this.bvcase_latest.id,this.bvcase.id)
      if (bvcase && records && type) {
        this.setBVCaseData({ bvcase, records })
      }
    }
  },
  methods: {
    ...mapActions('editor', [
      'setBVCaseData', 'incrementVersion'
    ]),
    prevVersion() {
      if (this.version.version > 1) {
        this.versionControls.currentVersion = this.version.version - 1
      }
    },
    nextVersion() {
      // console.log(this.version.version,this.bvcase_latest.version)
      if (this.version.version < this.bvcase_latest.version) {
        this.versionControls.currentVersion = this.version.version + 1
      }
    },
    reversion() {
      if (this.versionControls.currentVersion >= this.bvcase.version && this.bvcase.case_status != 1) {
        this.incrementVersion()

      }
    }
  }
}
</script>