<template>
  <div class="row">
    <div class="col-lg-8 m-auto">
      <form @submit.prevent="register" @keydown="form.onKeydown($event)">
        <card head foot :title="$t('login.register')">
          <!-- Name -->
          <form-group :di="{ name: 'name', label: $t('login.name') }" cols="3|7" bp="md">
            <textbox v-model="form.name" name="name" :class="{ 'is-invalid': form.errors.has('name') }" />
            <has-error :form="form" field="name" />
          </form-group>
          <!-- Email -->
          <form-group :di="{ name: 'email', label: $t('login.email') }" cols="3|7" bp="md">
            <textbox v-model="form.email" name="email"
              :class="{ 'is-invalid': form.errors.has('email') || form.errors.has('email_hash') }" />
            <has-error :form="form" field="email" />
            <has-error :form="form" field="email_hash" />
          </form-group>
          <!-- Password -->
          <form-group :di="{ name: 'password', label: $t('login.password') }" cols="3|7" bp="md">
            <textbox type="password" name="password" v-model="form.password"
              :class="{ 'is-invalid': form.errors.has('password') }" />
            <has-error :form="form" field="password" />
          </form-group>
          <!-- Password Confirmation -->
          <form-group :di="{ name: 'confirm_password', label: $t('login.confirm_password') }" cols="3|7" bp="md">
            <textbox type="password" name="password_confirmation" v-model="form.password_confirmation"
              :class="{ 'is-invalid': form.errors.has('password_confirmation') }" />
            <has-error :form="form" field="password_confirmation" />
          </form-group>
          <!-- Invite Token -->
          <form-group :di="{ name: 'invite_token', label: $t('login.invite_token') }" cols="3|7" bp="md">
            <textbox v-model="form.invite_token" name="invite_token"
              :class="{ 'is-invalid': form.errors.has('invite_token') }" />
            <has-error :form="form" field="invite_token" />
          </form-group>
          <template #footer-inner>
            <div class="form-group row">
              <div class="col-md-7 offset-md-3 d-flex">
                <!-- Submit Button -->
                <button class="btn btn-primary" type="submit" :class="[{ 'btn-loading': form.busy }]">
                  {{ $t('login.register') }}
                </button>
              </div>
            </div>
          </template>
        </card>
      </form>
    </div>
  </div>
</template>
<script>
import Form from 'vform'

export default {
  middleware: 'guest',
  metaInfo() {
    return { title: this.$t('register') }
  },
  data: () => ({
    form: new Form({
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      invite_token: ''
    }),
  }),

  methods: {
    async register() {
      // Register the user.
      const { data } = await this.form.post('/register')

      // Must verify email fist.
      // Log in the user.
      const { data: { token } } = await this.form.post('/login')

      // Save the token.
      this.$store.dispatch('auth/saveToken', { token })

      // Update the user.
      await this.$store.dispatch('auth/updateUser', { user: data })

      // Redirect home.
      this.$router.push({ name: 'candidates_home' })
    }
  }
}

</script>
