<template>
  <form @submit.prevent="update" @keydown="form.onKeydown($event)">
    <card head foot :title="$t('settings.your_password')">
      <alert type="success" v-if="alert_displayed" dismissable @dismiss="alert_displayed=false">
        <span v-text="$t('settings.info_updated')"></span>
      </alert>
      <!-- Password -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('settings.new_password') }}</label>
        <div class="col-md-7">
          <input v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }" class="form-control" type="password" name="password">
          <has-error :form="form" field="password" />
        </div>
      </div>
      <!-- Password Confirmation -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('settings.confirm_password') }}</label>
        <div class="col-md-7">
          <input v-model="form.password_confirmation" :class="{ 'is-invalid': form.errors.has('password_confirmation') }" class="form-control" type="password" name="password_confirmation">
          <has-error :form="form" field="password_confirmation" />
        </div>
      </div>
      <!-- Submit Button -->
      <div slot="footer" class="card-footer">
        <div class="col-md-9 ms-md-auto">
          <button class="btn btn-primary" type="submit" :class="[{'btn-loading':form.busy}]">
            {{ $t('settings.update') }}
          </button>
        </div>
      </div>
    </card>
  </form>
</template>
<script>
import Form from 'vform'

export default {
  scrollToTop: false,

  metaInfo() {
    return { title: this.$t('settings.title') }
  },

  data: () => ({
    alert_displayed:false,
    form: new Form({
      password: '',
      password_confirmation: ''
    })
  }),

  methods: {
    async update() {
      await this.form.patch('/api/settings/password')
      this.alert_displayed = true
      this.form.reset()
    }
  }
}

</script>
