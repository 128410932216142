<template>
<transition name="fade" mode="in-out">
    <card :title="$t('staffHome.admin_tools.title')" head>
        <div class="col-md-4">
            <card :title="$t('staffHome.admin_tools.staff')" head :body="false">
                <list-group flush>
                    <router-link :to="{name:'admin_staff'}" class="list-group-item" v-t="{path:'staffHome.admin_tools.staff_add_edit'}" />
                </list-group>
            </card>
        </div>
    </card>
</transition>
</template>

<script>
export default {
    name:'admin-tools',
    middleware: 'admin'
}
</script>
