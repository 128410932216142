<template>
  <div>
    <row class="d-flex align-items-center">
      <column :sizes="{ md: 5 }">
        <img :src="'/images/bvasvdi_logo.svg'" class="img-fluid bvasvdi_logo" />
      </column>
      <column :sizes="{ md: 7 }">
        <p style="font-size:2.5rem;" :class="['display-4', subtitleClass]">
          {{ $t('home.subtitle') }}
        </p>
      </column>
    </row>


    <h3 class="my-5 text-center">
      {{ $t('home.intro') }}
    </h3>
    <!-- <p class="lead my-3">{{$t('home.purpose')}}</p> -->
    <row style="margin-top:100px;">
      <column :sizes="{ md: 4 }" :offset="{ md: 4 }">
        <router-link class="btn w-100 mt-4 btn-primary" :to="{ name: 'login' }">
          <div class="fs-2">
            {{ $t('login.title') }}
          </div>
        </router-link>
      </column>
    </row>
    <row style="margin-top:100px;">
      <column :sizes="{ md: 4 }" :offset="{ md: 4 }">
        <alert type="info" class="d-flex flex-column">
          <fa icon="info-circle" size="3x" class="m-auto mb-4" />
          <i18n path="login.complete_reg_statement">
            <template #link>
              <router-link class="btn w-100 mt-4 btn-primary" to="/complete_registration">
                <div class="fs-4">
                  {{ $t('login.complete_reg') }}
                </div>
              </router-link>
            </template>
          </i18n>
        </alert>
      </column>
    </row>
  </div>
</template>
<script>
export default {
  name: 'welcome',
  layout: 'default',
  metaInfo() {
    return { title: this.$t('home.title') }
  },
  data: () => ({
    title: window.config.appName
  }),
  computed: {
    base_url() {
      return config.base_url;
    },
    subtitleClass() {
      var mqClasses = this.$filters.mq(this.$mq, { xs: 'text-center' })
      return mqClasses
    }
  },

}

</script>
