<template>
    <div>
        <div class="tiptap-toolbar" :class="['btn-group ']" v-if="editor">
            <!-- Existing Buttons -->
            <btn outline theme="secondary" @click.native="editor.chain().toggleBold().run()"
                :class="['p-1', { 'active': editor.isActive('bold') }]">
                <fa icon="bold" fixed-width />
            </btn>
            <btn outline theme="secondary" @click.native="editor.chain().toggleItalic().run()"
                :class="['p-1', { 'active': editor.isActive('italic') }]">
                <fa icon="italic" fixed-width />
            </btn>

            <!-- New Buttons -->
            <btn outline theme="secondary" @click.native="editor.chain().toggleUnderline().run()"
                :class="['p-1', { 'active': editor.isActive('underline') }]">
                <fa icon="underline" fixed-width />
            </btn>
            <btn outline theme="secondary" @click.native="editor.chain().toggleStrike().run()"
                :class="['p-1', { 'active': editor.isActive('strike') }]">
                <fa icon="strikethrough" fixed-width />
            </btn>
            <btn outline theme="secondary" @click.native="editor.chain().toggleBulletList().run()"
                :class="['p-1', { 'active': editor.isActive('bulletList') }]">
                <fa icon="list-ul" fixed-width />
            </btn>
            <btn outline theme="secondary" @click.native="editor.chain().toggleOrderedList().run()"
                :class="['p-1', { 'active': editor.isActive('orderedList') }]">
                <fa icon="list-ol" fixed-width />
            </btn>

            <!-- Add more buttons here -->
        </div>
        <editor-content :editor="editor" />
    </div>
</template>

<script>
import { Editor, EditorContent, FloatingMenu } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import { Mark, markInputRule, markPasteRule, mergeAttributes } from '@tiptap/core'

const Underline = Mark.create({
    name: 'underline',

    addOptions: {
        HTMLAttributes: {},
    },

    parseHTML() {
        return [
            {
                tag: 'u',
            },
            {
                style: 'text-decoration',
                getAttrs: value => value === 'underline',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['u', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
    },

    addCommands() {
        return {
            setUnderline: () => ({ commands }) => {
                return commands.setMark('underline')
            },
            toggleUnderline: () => ({ commands }) => {
                return commands.toggleMark('underline')
            },
            unsetUnderline: () => ({ commands }) => {
                return commands.unsetMark('underline')
            },
        }
    },

    addKeyboardShortcuts() {
        return {
            'Mod-u': () => this.editor.commands.toggleUnderline(),
        }
    },
})
export default {
    components: {
        EditorContent,
        FloatingMenu,
    },
    props: {
        value: String
    },
    data() {
        return {
            editor: null,
        }
    },

    mounted() {
        this.editor = new Editor({
            content: this.value,
            extensions: [
                StarterKit,
                Underline
            ],
            onUpdate: () => {
                // HTML
                this.$emit('input', this.editor.getHTML())

                // JSON
                // this.$emit('input', this.editor.getJSON())
            },
        })
    },

    beforeDestroy() {
        this.editor.destroy()
    },
    watch: {
        value(value) {
            const isSame = this.editor.getHTML() === value
            if (isSame) {
                return
            }
            this.editor.commands.setContent(value, false)
        },
    },
};

</script>
<style lang="scss"></style>