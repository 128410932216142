export default {
  computed: {
    paginationPerPageList() {
      return [
        { value: '15', label: '15' },
        { value: '30', label: '30' },
        { value: '45', label: '45' },
      ]
    },
  },
  methods: {
    updatePagination(link) {
      var url = new URL(link.url, this.pagination.path)
      var search = url.search.substring(1);
      var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      this.$router.replace({ name: this.$route.name, query: { page: params.page } })
      this.makeRequest(params);
    },
    updatePerPage() {
      this.setLocalStorage('per_page', this.pagination.per_page ? this.pagination.per_page : 15)
      this.makeRequest();
    },
    makeRequest(params = {}) {
      var filters = this.addFilterKeys()

      params = { ...params, ...this.params, ...this.$route.query, ...filters }
      if (this.getLocalStorage('sort')) params.sort = this.getLocalStorage('sort')
      if (this.getLocalStorage('per_page')) params.per_page = this.getLocalStorage('per_page')
      var self = this
      // if (JSON.stringify(params) != JSON.stringify(this.params)) {
      //   //   params = { ...params, ...this.params, ...filters }
      this[this.$options.apiMethod]({ params })
        .finally(async (resp) => {
          this.$nextTick(() => {
            if (this.pagination.last_page < this.pagination.current_page) {
              this.$router.replace({ name: this.$route.name, query: { page: this.pagination.last_page } })
            }
          });
        });
      // }

    },
    addFilterKeys() {
      var filters = {}
      for (var item in this.filters) {
        if (this.filters[item]) filters['filter[' + item + ']'] = this.filters[item]
      }
      return filters
    },
    setSorting(column) {
      var name = this.$route.name
      var params = this.params
      params.sort = params.sort == column ? '-' + column : column
      this.setLocalStorage(name + '.sort', params.sort)
      this.makeRequest(params);
    },
    sortingClass(col) {
      var sort = this.params.sort
      return sort == col.name ? 'sorting' : sort == '-' + col.name ? 'sorting-desc' : ''
    },
    getLocalStorage(field) {
      var name = this.$route.name
      return localStorage.getItem(name + '.pagination.' + field)
    },
    setLocalStorage(field, value) {
      var name = this.$route.name
      localStorage.setItem(name + '.pagination.' + field, value)
    }
  },
  watch: {
    '$route.query.page'(newVal, oldVal) {
      // if (this.$route.query.page) this.pagination.current_page = this.$route.query.page
      this.makeRequest();
    },
    // 'pagination.page'(newVal, oldVal) {
    //   if (oldVal && newVal != oldVal) {
    //     this.makeRequest();
    //   }
    // },
    // pagination: {
    //   handler(newVal, oldVal) {
    //     if (oldVal && newVal != oldVal) {
    //       console.log('pagination changed', newVal, oldVal)
    //       this.makeRequest();
    //     }
    //   }, deep: true
    // }
  },
}
