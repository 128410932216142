<template>
  <div>
    <stage-progress v-if="isTrainingCase"></stage-progress>
    <row class="g-2">
      <column :sizes="{ lg: 4, sm: 12 }">
        <div class="sticky-top">
          <card :title="bvcase.name" v-if="!loadingCandidateCaseData" head foot :body="false" class="mb-4">
            <span v-if="isStaff" class="card-heading" slot="header-right" v-text="'Version: ' + bvcase.version"></span>
            <transition-group name="fade" mode="out-in">
              <div v-if="bvcase.case_type == 'assessment' || isTrainingCase && !currentStageSelected" class="card-body"
                v-html="bvcase.question" :key="1"></div>
              <div v-if="isTrainingCase && currentStageSelected" class="card-body" v-html="currentStage.question"
                :key="2"></div>
            </transition-group>
            <div slot="footer">
            </div>
          </card>
          <card title="Errors" v-if="!loadingCandidateCaseData && marked && markedErrors.length"
            class="text-white bg-danger my-2 mb-4" :body="false" head>
            <div class="list-group  list-group-flush">
              <list-group-item class="list-group-item-danger" v-for="field in markedErrors" :key="field">
                <fa icon="times" class="float-end" size="lg" />
                <b v-html="groupTitle(field)"></b>
                <template v-if="dataitems[field] && dataitems[field].lookup">
                  <scope :rec="records[field]"
                    :lookupitem="getLookupValue(dataitems[field].lookup.items, bvcase[bvcase.questionnaire_type][field])"
                    v-slot="{ lookupitem }">
                    <b v-if="lookupitem">
                      <span v-html="dataitems[field].label"></span>
                      : {{ lookupitem }}
                    </b>
                    <b v-else-if="dataitems[field]" v-html="dataitems[field].label"></b>
                    <i v-if="lookupitem" v-html="$t('candidates.should_have_selected')"></i>
                    <i v-else v-html="$t('candidates.should_not_have_selected')"></i>
                  </scope>
                </template>
                <template v-else>
                  <b v-if="dataitems[field]" v-html="dataitems[field].label"></b>
                  <i v-if="records[field]" v-html="$t('candidates.should_not_have_selected')"></i>
                  <i v-else v-html="$t('candidates.should_have_selected')"></i>
                </template>

                <div class="alert alert-danger mx-n2 mt-2 mb-0" v-if="hasCommonError(field)"
                  v-html="getCommonError(field)">
                </div>
              </list-group-item>
            </div>
          </card>
        </div>
        <transition name="fade-fast">
          <fa icon="save" spin size="3x" v-if="saving"></fa>
        </transition>
      </column>
      <column :sizes="{ lg: 8, sm: 12 }">
        <component class="mb-4" :is="bvcase.questionnaire_type" v-if="questionnaireVisible" ref="questionnaire"
          :marked="marked" :marked-errors="markedErrors"
          :readonly="isStaff && bvcase.locked == 1 || isCandidate && marked" @update-groups="updateGroups">
        </component>

      </column>
    </row>
    <card class="mb-4 mt-4" v-if="!loadingCandidateCaseData && questionnaireVisible">
      <row>
        <column v-if="!loadingCandidateCaseData && $refs.questionnaire" :sizes="[8, { sm: 4, md: 4, lg: 3, xl: 2 }]"
          class="offset-2" :offset="{ sm: 0, xl: 1 }">
          <donut v-if="!isTrainingCase && marked" :percent="totalScorePercent" :sections="totalSections"
            :percent-pass="50" />
          <donut v-if="isTrainingCase && stageIsMarked" :percent="stageScorePercent" :sections="totalSections"
            :percent-pass="50" />
        </column>
        <column :offset="{ xl: 1 }">
          <btn v-if="!isTrainingCase && !marked" block btn-class="primary" @click.native="save"
            v-t="'candidates.grade_my_attempt'"></btn>
          <btn v-if="isTrainingCase && !stageIsMarked" block btn-class="primary" @click.native="markStage"
            v-t="'candidates.check_my_work'"></btn>

          <alert :type="totalScorePercent >= 50 ? 'success' : 'danger'"
            v-if="!isTrainingCase && marked && !isInitialAssessment">
            <span v-html="caseFeedback"></span>
          </alert>
          <alert v-if="!isTrainingCase && marked && isInitialAssessment"
            :type="totalScorePercent >= 50 ? 'success' : 'danger'">
            <p v-if="totalScorePercent > 50" class="my-auto" v-t="'candidates.initial_assessment.pass'"></p>
            <p v-if="totalScorePercent < 50" class="my-auto" v-t="'candidates.initial_assessment.fail'"></p>
            <hr>
            <btn v-if="isCandidate" is-router-link :to="{ name: 'candidates_home' }">GO</btn>
            <btn v-else is-router-link :to="{ name: 'candidate', params: { candidate_id: candidate.id } }">
              GO
            </btn>
          </alert>
          <div v-if="isStaff && !isTrainingCase && marked" class="mt-4">
            <div v-if="isStaff && !isInitialAssessment" class="mt-2">
              <h4 v-t="'candidates.reset_case'"></h4>
              <btn @click.native="resetCase(candidate_case)">
                <fa icon="sync" :spin="isResetting" aria-hidden="true"></fa>
              </btn>
            </div>
            <div v-if="isStaff && collectionHasReserveCases(candidate_collection)" class="mt-2">
              <h4 v-t="'candidates.reserve_cases'"></h4>
              <transition-group
                v-if="replacedCases(candidate_collection).length || isStaff && getCollectionReserveCases(candidate_collection).length"
                class="case-collection replaced p-1 d-inline-block" name="fade" appear>
                <template v-if="isStaff">
                  <a @click="showCaseReplaceBtn(ccase)" class="case col-lg-1 col-sm-3 p-1"
                    v-for="(ccase, n) in getCollectionReserveCases(candidate_collection)" :key="ccase.id"
                    v-popover="getCasePopover(ccase, candidate_collection)">
                    <donut v-if="!ccase.replaced" :class="{ 'selected': ccase == selectedReserveCase }"
                      :label="(n + 1).toString()" :percent="ccase.score" :inner-radius="20" :outer-radius="24"
                      :percent-pass="50" :enlarge-when-updated="true" />
                    <donut v-else :label="(n + 1).toString()" :percent="ccase.replaced.score" :inner-radius="20"
                      :outer-radius="24" :percent-pass="50" :enlarge-when-updated="true" />
                  </a>
                </template>
              </transition-group>
              <btn v-if="isStaff && selectedReserveCase" @click.native="swapWithReserveCase(candidate_case)">
                <span v-html="$t('candidates.swap_case')"></span>
              </btn>
            </div>
          </div>
          <div class="mt-4" v-if="!isInitialAssessment">
            <btn @click.native="$router.push({ name: isCandidate ? 'candidates_home' : 'candidate' })"
              class="float-start" btnClass="secondary" v-t="'candidates.back'"></btn>
            <btn
              @click.native="$router.push({ name: nextUnstartedCase ? candidateCaseRoute : isCandidate ? 'candidates_home' : 'candidate', params: { case_id: nextUnstartedCase ? nextUnstartedCase.case_id : false } })"
              class="float-end" btnClass="primary" v-t="'candidates.next'"></btn>
          </div>
        </column>
      </row>
    </card>
  </div>
</template>
<script>
import stageMixin from '~/mixins/stage-mixin.js'
import case_collections from '~/mixins/case-collections.js'
import commonErrorsMixin from '~/mixins/common-errors-mixin.js'
import stageProgress from '~/components/stage-progress.vue'
import { omit, find, isEqual, each, reduce } from 'lodash'

import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  middleware: 'auth',
  mixins: [stageMixin, case_collections, commonErrorsMixin],
  components: { stageProgress },
  mounted() {
    this.routeChanged()
  },
  data() {
    return {
      case_id: null,
      marked: false,
      selectedReserveCase: false,
      groups: {},
    }
  },
  metaInfo() {
    return { title: 'Candidate Case:' + this.bvcase.name }
  },

  ignoredFields: ['id', 'candidate_id', 'case_id', 'BV_Score', 'PV_Score', 'PVDI_SCORE', 'created_at', 'updated_at', 'deleted_at', 'archived_at', 'archived_by', 'common_errors', 'bvcase'],
  computed: {
    ...mapState('auth', ['user']),
    // ...mapState('editor', ['bvcase', 'stage', 'candidate', 'candidate_case', 'collections', 'candidate_collections', 'dataitems', 'records', 'validation', 'loaded', 'saving', 'mode']),
    ...mapState('resources/candidate', ['bvcase', 'stage', 'candidate', 'candidate_case', 'collections', 'candidate_collections', 'dataitems', 'records', 'validation', 'loadingCandidateCaseData', 'saving', 'mode']),
    ...mapGetters('editor', ['nextUnstartedCase']),
    ...mapGetters('auth', ['isCandidate', 'isStaff', 'isAdmin']),
    candidateCaseRoute() {
      if (this.isCandidate) return 'candidates_case'
      if (this.isStaff || this.isAdmin) return 'candidate_case'
      return 'candidates_case'
    },
    isInitialAssessment() {
      return ['initial_assessment', 'admin.initial_assessment'].includes(this.$route.name)
    },
    caseFeedback() {
      var qType = this.bvcase.questionnaire_type
      var total = this.totalScorePercent
      var feedbackState = 'default'
      if (qType == 'bvas') {
        if (total >= 85) feedbackState = 'high'
        else if (total >= 50) feedbackState = 'pass'
        else if (total < 50) feedbackState = 'fail'
      }
      if (qType == 'vdi') {
        if (total >= 75) feedbackState = 'high'
        else if (total >= 50) feedbackState = 'pass'
        else if (total < 50) feedbackState = 'fail'
      }
      if (qType == 'pvas') {
        if (total >= 80) feedbackState = 'high'
        else if (total >= 50) feedbackState = 'pass'
        else if (total < 50) feedbackState = 'fail'
      }
      if (qType == 'pvdi') {
        if (total >= 80) feedbackState = 'high'
        else if (total >= 50) feedbackState = 'pass'
        else if (total < 50) feedbackState = 'fail'
      }
      return this.$t('questionnaires.' + qType + '.feedback.' + feedbackState)
    },
    candidate_collection() {
      var candidate_collection_id = this.candidate_case.candidate_collection_id
      return find(this.candidate_collections, (c) => { return c.id == candidate_collection_id })
    },
    questionnaireVisible() {
      if (this.isTrainingCase && !this.currentStageSelected) return false;
      return true
    },
    markedErrors() {
      var candidate = omit(this.records, this.$options.ignoredFields);
      var original = omit(this.bvcase[this.bvcase.questionnaire_type], this.$options.ignoredFields);
      var items = {};
      each(candidate, (record, di) => {
        var nameArr = di.split('_')
        if (nameArr[1] === 'Ot' || nameArr[1] === 'Score') {
          return;
        }
        if (this.hiddenForCandidates(nameArr[1])) return;
        items[di] = record
      });
      var result = reduce(items, function (r, v, k) {
        return v == original[k] ? r : r.concat(k);
      }, []);
      return result
    },
    type() {
      return this.bvcase.questionnaire_type
    },
    sections() {
      return this.$refs.questionnaire[this.type + '_items']
    },
    maxScore() {
      var original = omit(this.bvcase[this.bvcase.questionnaire_type], this.$options.ignoredFields);
      var score = 0
      each(original, (val, di) => {
        var nameArr = di.split('_')
        if (nameArr[1] === 'Ot' || nameArr[1] === 'Score' || nameArr[1] === 'PD') { return; }
        if (val) score++
      })
      return score
    },
    original() {
      return omit(this.bvcase[this.bvcase.questionnaire_type], this.$options.ignoredFields);
    },
    submittedCase() {
      return omit(this.records, this.$options.ignoredFields);
    },
    correct() {
      var score = 0
      each(this.submittedCase, (val, di) => {
        var nameArr = di.split('_')
        if (nameArr[0] === 'archived' || nameArr[1] === 'Ot' || nameArr[1] === 'Score' || nameArr[1] === 'PD') { return; }
        if (val) val = val.toString();
        if (this.original[di]) this.original[di] = this.original[di].toString();
        if (val && isEqual(val, this.original[di])) score++
      })
      return score
    },
    incorrect() {
      var score = 0
      each(this.submittedCase, (val, di) => {
        var nameArr = di.split('_')
        if (nameArr[0] === 'archived' || nameArr[1] === 'Ot' || nameArr[1] === 'Score' || nameArr[1] === 'PD') { return; }

        if (val) val = val.toString();
        if (this.original[di]) this.original[di] = this.original[di].toString();
        // if (!isEqual(val, this.original[di])) console.log(di, val, this.original[di])
        if (!isEqual(val, this.original[di])) score++
      })
      return score
    },
    totalSections() {
      var test = omit(this.sections, ['Ot'])
      return Object.keys(test).length
    },
    totalScorePercent() {
      var percent = Math.floor(this.marked ? ((this.maxScore - this.incorrect) / this.maxScore) * 100 : false)

      var bvPdNe = !isEqual(
        parseInt(this.records.BV_PD_Only, 10),
        parseInt(this.bvcase[this.bvcase.questionnaire_type].BV_PD_Only, 10)
      );
      var pvPdNe = !isEqual(
        parseInt(this.records.PV_PD_Only, 10),
        parseInt(this.bvcase[this.bvcase.questionnaire_type].PV_PD_Only, 10)
      );
      if (this.bvcase.questionnaire_type == 'bvas') {
        if (bvPdNe) percent = percent / 2
      }

      if (this.bvcase.questionnaire_type == 'pvas') {
        if (pvPdNe) percent = percent / 2
      }
      return percent >= 0 ? percent : 0
    }
  },
  methods: {
    ...mapActions('editor', ['saveCaseData']),
    ...mapActions('resources/candidate', ['getCandidateCaseData', 'saveCandidateCaseData', 'updateStage']),
    routeChanged() {
      var params = this.$route.params
      if (!this.case_id) {
        if (['initial_assessment', 'admin.initial_assessment'].includes(this.$route.name)) params.initial_assessment = true
        this.getCandidateCaseData({ params: params });
        this.case_id = params.case_id
        if (params.stage) this.updateStage(params.stage)
        return;
      }
      if (params.case_id == this.case_id && params.stage != this.stage) {
        this.updateStage(params.stage)
      } else {
        if (['initial_assessment', 'admin.initial_assessment'].includes(this.$route.name)) params.initial_assessment = true
        this.getCandidateCaseData({ params: params })
        this.case_id = parseInt(params.case_id)
      }
    },
    updateGroups(groups) {
      this.groups = groups
    },
    groupTitle(name) {
      var nameArr = name.split('_')
      var section = nameArr[1];
      if (this.groups && this.groups[section])
        return this.groups[section].title + ': '
      return ''
    },

    save() {
      if (this.isEditor) this.saveCaseData({ bvcase: this.bvcase, records: this.records })
      if (this.isCandidate) {
        var data = { bvcase: this.bvcase, records: this.records }
        if (['initial_assessment', 'admin.initial_assessment'].includes(this.$route.name)) data.initial_assessment = true
        this.saveCandidateCaseData({ bvcase: this.bvcase, records: this.records })
          .then(() => {
            this.$gtag.config({ user_id: this.user.id });
            this.$gtag.event('save', {
              'event_category': 'CandidateCase',
              'event_label': 'candidate case saved',
              'value': this.case_id
            })
          })
      }

    },
    mark() {
      this.marked = true;
    },
    scoreSection(k) {
      if (this.type == 'bvas' && k == 'Ot') return 0;
      var score = 100 / this.totalSections
      each(this.markedErrors, (err) => {
        var errArr = err.split('_')
        score = errArr[1] == k ? 0 : score;
      })
      return score
    },
    showCaseReplaceBtn(ccase) {
      if (this.selectedReserveCase == ccase) this.selectedReserveCase = false
      else this.selectedReserveCase = ccase
    },
    getCasePopover(ccase, collection, reserve = false) {
      var myCase = (ccase.replaced) ? ccase.replaced : ccase

      var html = this.getCaseAuditSummary(myCase.audits).join('<br/>');
      if (ccase.replaced) html += '<br/>' + this.getCaseReplacementSummary(ccase, collection).join('<br/>');
      return { event: 'hover', title: this.getBVCaseName(myCase, collection), html }
    },
    getCaseAuditSummary(audits) {
      var summary = []
      if (audits && audits.length) {
        for (let index = 0; index < audits.length; index++) {
          const a = audits[index];
          if (a.event == "created") {
            summary.push(this.$t('admin.audit.candidate_case.created', { date: this.$d(new Date(a.created_at), 'numeric_datetime', 'en-GB') }))
          } else {
            summary.push(this.$t('admin.audit.candidate_case.updated', { user: this.candidate.user_id == a.user_id ? 'candidate' : a.user_id, date: this.$d(new Date(a.updated_at), 'numeric_datetime', 'en-GB') }))
          }

        }

      }
      return summary
    },
    getCaseReplacementSummary(ccase, collection) {
      var summary = []
      if (ccase.replaced) {
        var replaced = this.getCandidateCase(ccase.replaced.case_id, collection)
        var replacedCaseName = this.getBVCaseName(ccase, collection)
        summary.push(this.$t('admin.audit.candidate_case.replaced', { case: replacedCaseName }))
      }
      return summary
    },
    getLookupValue(lookupitems, value) {
      var lookup = lookupitems
      var item = find(lookup, (item) => { return item.value == value })
      return item ? item.label : value
    },
  },
  watch: {
    candidate_case(newCase, oldCase) {
      this.$nextTick(() => {
        if (newCase.pivot && newCase.pivot.score != null) this.marked = true
        else if (newCase.score != null) this.marked = true
        else this.marked = false

      });
    },
    // call the method again if the route changes
    '$route': 'routeChanged',
  },
}

</script>
