<template>
  <card :title="$t('staffHome.cases.collections.title')" head :body="false" foot class="case-collections-card">
    <router-link v-if="$route.name != 'cases'" :class="['btn btn-close']" slot="header-right" :to="{ name: 'cases' }"
      aria-label="Close" />
    <list-group flush>
      <case-collection show-edit v-for="collection in collections" :collection="collection"
        :key="collection.id"></case-collection>
    </list-group>
    <template #footer-inner>
      <btn is-router-link :to="{ name: 'new_collection' }" outline slot="footer">
        <fa icon="star" /> <span v-t="{ path: 'staffHome.cases.collections.new_collection' }"></span>
      </btn>
    </template>
  </card>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CaseCollection from './CaseCollection.vue'
export default {
  components: {
    CaseCollection
  },
  computed: {
    ...mapState('editor', ['collections']),
  },
  mounted() {

  },
  methods: {
    ...mapActions('editor', ['getCaseCollections', 'update']),
  },
  watch: {
    '$route': {
      handler() {
        this.update({ collection: false })
        this.getCaseCollections()
      },
      immediate: true
    },
  },
}

</script>
