<template>
  <div class="row">
    <div class="col-lg-8 m-auto">
      <form @submit.prevent="send" @keydown="form.onKeydown($event)">
        <card head foot :title="$t('login.reset_password')">
          <alert type="success" dismissable v-if="status">
            <span>{{ status }}</span>
          </alert>
          <!-- Email -->
          <div class="form-group row">
            <label class="col-md-3 col-form-label text-md-right">{{ $t('login.email') }}</label>
            <div class="col-md-7">
              <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" class="form-control"
                type="email" name="email">
              <has-error :form="form" field="email" />
            </div>
          </div>
          <!-- Submit Button -->
          <footer class="card-footer" slot="footer">
            <div class="form-group row mb-0">
              <div class="col-md-9 ms-md-auto">
                <button class="btn btn-primary" type="submit" :class="[{ 'btn-loading': form.busy }]">
                  {{ $t('login.send_password_reset_link') }}
                </button>
              </div>
            </div>
          </footer>
        </card>
      </form>
    </div>
  </div>
</template>
<script>
import Form from 'vform'

export default {
  middleware: 'guest',

  metaInfo() {
    return { title: this.$t('login.reset_password') }
  },

  data: () => ({
    status: '',
    form: new Form({
      email: ''
    })
  }),

  methods: {
    async send() {
      const { data } = await this.form.post('password/email')

      this.status = data.status

      this.form.reset()
    }
  }
}

</script>
