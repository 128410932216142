import axios from 'axios'
import { cloneDeep, each, find, findIndex } from 'lodash'
// state
export const state = {
  candidate: {},
  candidates: [],
  training_sessions: [],
  collection: false,
  collections: [],
  cases: [],
  diagnoses: [],
  mode: 'editor',
  loaded: false,
  saving: false,
  candidate_case: {},
  candidate_collections: [],
  bvcase: { type: null },
  stage: null,
  bvcase_latest: { type: null },
  questionnaires: {},
  diagnoses: [],
  records: {},
  dataitems: [],
  training_session: {},
  validation: {},
  versions: [],
  pagination: {},
  ui: {
    candidates: {
      columns: ['id', 'user_id', 'name', 'email', 'training_session'],
      pagination: {
        current: 1,
        per_page: 20,
        sort_by: 'id',
        sort_dir: 1
      },
      grouped: true,
      training_session_id: 2
    },
    cases: {
      columns: ['id', 'name', 'diagnosis', 'case_type', 'questionnaire_type', 'difficulty', 'review1', 'review2', 'locked', 'case_status'],
      pagination: {
        current: 1,
        per_page: 30,
        sort_by: 'name',
        sort_dir: 1
      },
      filter: {
        name: null,
        diagnosis: null,
        case_type: null,
        questionnaire_type: null,
        difficulty: null
      },
      filterRowVisible: false
    },
    versionControls: {
      visible: false,
      showChanges: false,
      currentVersion: false,
    }
  },
  users: []
}

// getters
export const getters = {
  nextUnstartedCase: state => {
    var first = false;
    if (state.collections == null || state.collections.length == 0) return false;
    var collection_ids = state.collections.map((cc) => { if (cc) return cc.id })
    var filteredCollections = state.candidate_collections.filter((cc) => {
      return collection_ids.includes(cc.collection_id)
    })
    each(filteredCollections, (coll) => {
      if (first) return;
      first = find(coll.cases, (cc) => { return cc.score == null })
    })
    return first
  }
}

// mutations
export const mutations = {
  GET_STAFF_DATA(state, data) {
    state.users = data.staff
    state.training_sessions = data.training_sessions
    state.loaded = true
  },
  ADD_STAFF_MEMBER(state, data) {
    state.users.push(data)
    state.loaded = true
  },
  UPDATE_STAFF_MEMBER(state, data) {
    // console.log('UPDATE_STAFF_MEMBER') 
    var index = findIndex(state.users, (user) => { return user.id == data.id })
    if (index > -1) state.users.splice(index, 1, data)
    state.loaded = true
  },
  DELETE_STAFF_MEMBER(state, id) {
    var index = findIndex(state.users, (user) => { return user.id == id })
    if (index > -1) state.users.splice(index, 1)
    state.loaded = true
  },
  SAVE_TRAINING_SESSION(state, data) {
    var index = findIndex(state.training_sessions, (ts) => { return ts.id == data.id })
    if (index > -1) state.training_sessions.splice(index, 1, data)
    state.loaded = true
  },
  DELETE_TRAINING_SESSION(state, id) {
    var index = findIndex(state.training_sessions, (ts) => { return ts.id == id })
    if (index > -1) state.training_sessions.splice(index, 1)
    state.loaded = true
  },
  ADD_NEW_CANDIDATE(state, data) {
    // state.candidates.push(data)
    state.loaded = true
  },
  UPDATE_CANDIDATE(state, data) {
    // console.log('UPDATE_CANDIDATE')

    var index = findIndex(state.candidates, (c) => { return c.id == data.id })
    if (index > -1) state.candidates.splice(index, 1, data)
    state.loaded = true
  },
  DELETE_CANDIDATE(state, data) {
    // console.log('DELETE_CANDIDATE')

  },
  ASSIGN_CANDIDATE_TO_TRAINING_SESSION(state, data) {
    // console.log('ASSIGN_CANDIDATE_TO_TRAINING_SESSION', data)
  },
  GET_TRAINING_SESSIONS(state, data) {
    state.training_sessions = data.training_sessions
    state.loaded = true
  },
  GET_CASE_LIST(state, data) {
    state.cases = data.cases
    state.diagnoses = data.diagnoses
    state.loaded = true
  },
  GET_CANDIDATES(state, resp) {
    state.candidates = resp.data.candidates
    state.pagination = resp.meta
    state.training_sessions = resp.data.training_sessions
    state.loaded = true
  },
  GET_CANDIDATE_CASE_LIST(state, data) {
    state.candidate = data.candidate
    state.collections = data.collections
    state.candidate_collections = data.candidate_collections
    state.training_session = data.training_session
    state.loaded = true
  },
  GET_CASE_DATA(state, data) {
    var type = data.bvcase.questionnaire_type;
    state.bvcase = data.bvcase
    state.bvcase_latest = state.bvcase
    state.dataitems = data.questionnaires[type].dataitems
    state.diagnoses = data.diagnoses
    state.questionnaires = data.questionnaires
    state.records = state.bvcase[type]
    state.validation = data.questionnaires[type].validation
    state.versions = data.versions
    state.loaded = true
  },
  GET_CANDIDATE_CASE_DATA(state, data) {
    // console.log(data.records)
    state.candidate = data.candidate
    state.candidate_case = data.candidate_case
    state.bvcase = data.bvcase
    state.candidate_collections = data.candidate_collections
    state.collection = data.collection
    state.collections = data.collections
    state.records = data.records
    state.dataitems = data.dataitems
    // state.validation = data.validation
    state.loaded = true
  },
  CHANGE_CASE_TYPE(state, type) {
    state.bvcase.case_type = type
  },
  CHANGE_QUESTIONNAIRE_TYPE(state, type) {
    var q = state.questionnaires[type]
    state.bvcase.questionnaire_type = type
    if (!state.bvcase[type]) state.bvcase[type] = q.records

    state.dataitems = q.dataitems
    state.validation = q.validation
    state.records = state.bvcase[type]
    if (state.records && !state.records.common_errors) state.records.common_errors = []
    state.loaded = true
  },
  CLEAR_BVCASE_DATA(state) {
    state.bvcase = {}
    state.collection = {} /* ? */
    state.records = {}
    state.loaded = false
  },
  CLEAR_CANDIDATE_DATA(state) {
    state.collections = []
  },
  SET_BVCASE_DATA(state, data) {
    state.bvcase = data.bvcase
    state.records = data.records
  },
  GET_NEW_CASE_DATA(state, data) {
    state.bvcase.version = 1;
    state.bvcase_latest = state.bvcase
    state.questionnaires = data.questionnaires
    state.diagnoses = data.diagnoses
    state.records = { common_errors: [] }
    state.loaded = true
  },
  REPLACE_CANDIDATE_CASE(state, data) {
    state.candidate_collections = data.candidate_collections
    if(data.candidate_collections[0].candidate_id == state.candidate.id)
    state.candidate.collections = data.candidate_collections
  },
  RESET_CANDIDATE_CASE(state, data) {
    state.candidate_collections = data.candidate_collections
    state.candidate_case = data.candidate_case

  },
  GET_CASE_COLLECTIONS(state, data) {
    state.collections = data.collections
    state.loaded = true
  },
  GET_CASE_COLLECTION(state, data) {
    state.collection = data.collection
    state.loaded = true
  },
  SAVE_CASE_COLLECTION(state, data) {
    state.collection = data.collection
    state.loaded = true
  },
  UPDATE_CASE_DATA(state, data) {
    state.bvcase = data.bvcase
    if (data.records) state.records = data.records
    state.saving = false
  },
  UPDATE_CANDIDATE_CASE_DATA(state, data) {
    state.candidate_case = data.candidate_case
    state.candidate_collections = data.candidate_collections
    state.records = data.records
    state.saving = false
    state.loaded = true
  },
  UPDATE(state, data) {
    each(data, (val, field) => {
      state[field] = val
    })
  },
  UPDATE_RECORD(state, data) {
    // console.log('UPDATE_RECORD', data) 
    state.records[data.field] = data.value
  },
  INCREMENT_CASE_VERSION(state) {
    var type = state.bvcase.questionnaire_type;
    state.bvcase = cloneDeep(state.bvcase)
    state.bvcase.id = null
    state.bvcase.version = state.bvcase.version + 1;
    state.versions.push(state.bvcase);
    state.bvcase_latest = state.bvcase
    state.records = state.bvcase[type]
    state.bvcase.locked = false
    state.ui.versionControls.currentVersion = state.bvcase.version
  },
  UPDATE_STAGE(state, data) {
    state.stage = data
  },
}

// actions
export const actions = {
  getStaffData({ commit, state }, params) {
    state.loaded = false
    return axios.get('/admin/getStaff', params)
      .then((resp) => {
        commit('GET_STAFF_DATA', resp.data)
      });
  },
  addStaffMember({ commit, state }, params) {
    state.loaded = false
    return axios.post('/admin/addStaffMember', params)
      .then((resp) => {
        commit('ADD_STAFF_MEMBER', resp.data)
      });
  },
  updateStaffMember({ commit, state }, params) {
    // console.log('updateStaffMember') 
    state.loaded = false
    return axios.post('/admin/updateStaffMember', params)
      .then((resp) => {
        commit('UPDATE_STAFF_MEMBER', resp.data)
      });
  },
  deleteStaffMember({ commit, state }, params) {
    state.loaded = false
    return axios.post('/admin/deleteStaffMember', params)
      .then((resp) => {
        commit('DELETE_STAFF_MEMBER', resp.data)
      });
  },
  addNewCandidate({ commit, state }, params) {
    state.loaded = false
    return axios.post('/candidate/new', params)
      .then((resp) => {
        commit('ADD_NEW_CANDIDATE', resp.data)
      });
  },
  getTrainingSessions({ commit, state }, params) {
    state.loaded = false
    return axios.get('/training/getTrainingSessions', params)
      .then((resp) => {
        commit('GET_TRAINING_SESSIONS', resp.data)
      });
  },
  saveTrainingSession({ commit, state }, params) {
    state.loaded = false
    return axios.post('/training/saveTrainingSession', params)
      .then((resp) => {
        commit('SAVE_TRAINING_SESSION', resp.data)
      });
  },
  deleteTrainingSession({ commit, state }, params) {
    state.loaded = false
    return axios.post('/training/deleteTrainingSession', params)
      .then((resp) => {
        commit('DELETE_TRAINING_SESSION', resp.data)
      });
  },
  updateCandidate({ commit, state }, params) {
    // console.log('updateCandidate')
    return axios.post('/candidate/updateCandidate', params)
      .then((resp) => {
        commit('UPDATE_CANDIDATE', resp.data)
      })
  },
  deleteCandidate({ commit, state }, params) {
    // console.log('deleteCandidate')
    return axios.post('/candidate/delete', params)
      .then((resp) => {
        commit('DELETE_CANDIDATE', resp.data)
      })
  },
  assignCandidateToTrainingSession({ commit, state }, params) {
    return axios.post('/candidate/assignToTrainingSession', params)
      .then((resp) => {
        commit('ASSIGN_CANDIDATE_TO_TRAINING_SESSION', resp.data)
      })
  },
  getCandidates({ commit, state }, params) {
    state.loaded = false
    return axios.get('/getCandidates', params)
      .then((resp) => {
        commit('GET_CANDIDATES', resp.data)
      });
  },
  getCaseList({ commit, state }) {
    state.loaded = false
    return axios.get('/caseList')
      .then((resp) => {
        commit('GET_CASE_LIST', resp.data)
      });
  },
  getCaseCollection({ commit, state }, params) {
    state.loaded = false
    // console.log('getCaseCollection')
    return axios.get('/caseCollection', params)
      .then((resp) => {
        commit('GET_CASE_COLLECTION', resp.data)
      });
  },
  getCaseCollections({ commit, state }) {
    state.loaded = false
    // console.log('getCaseCollections')
    return axios.get('/caseCollections')
      .then((resp) => {
        commit('GET_CASE_COLLECTIONS', resp.data)
      });
  },
  saveCaseCollection({ commit, state }, params) {
    // state.loaded = false
    return axios.post('/caseCollection', params)
      .then((resp) => {
        commit('SAVE_CASE_COLLECTION', resp.data)
      });
  },
  getCandidateCaseList({ commit, state }, params) {
    state.loaded = false
    return axios.get('/candidateCaseList', params)
      .then((resp) => {
        commit('GET_CANDIDATE_CASE_LIST', resp.data)
      });
  },
  getCandidateCaseData({ commit, dispatch, state }, params) {
    state.loaded = false
    return axios.get('/candidateCaseData', params)
      .then((resp) => {
        dispatch('lang/setupQuestionnaireLang', resp.data, { root: true })
        commit('GET_CANDIDATE_CASE_DATA', resp.data)
      });
  },
  getNewCaseData({ commit, state }, params) {
    state.loaded = false
    return axios.get('/newCaseData', params)
      .then((resp) => {
        commit('GET_NEW_CASE_DATA', resp.data)
      });
  },
  replaceCandidateCase({ commit, state }, params) {
    return axios.post('/replaceCandidateCase', params)
      .then((resp) => {
        commit('REPLACE_CANDIDATE_CASE', resp.data)
      });
  },
  resetCandidateCase({ commit, state }, params) {
    return axios.post('/resetCandidateCase', params)
      .then((resp) => {
        commit('RESET_CANDIDATE_CASE', resp.data)
      });
  },
  changeCaseType({ commit, state }, type) {
    if (!state.bvcase.locked) {
      commit('CHANGE_CASE_TYPE', type)
    }
  },
  changeQuestionnaireType({ commit, state }, type) {
    if (!state.bvcase.locked && state.questionnaires[type]) {
      commit('CHANGE_QUESTIONNAIRE_TYPE', type)
    }
  },
  getCaseData({ commit, dispatch, state }, params) {
    state.loaded = false;
    return axios.get('/caseData', { params })
      .then((resp) => {
        dispatch('lang/setupAllQuestionnairesLang', resp.data, { root: true })
        commit('GET_CASE_DATA', resp.data)

      });
  },
  clearCandidateData({ commit, state }) {
    commit('CLEAR_CANDIDATE_DATA')
  },
  clearBVCaseData({ commit, state }) {
    commit('CLEAR_BVCASE_DATA')
  },
  setBVCaseData({ commit, state }, bvcase) {
    commit('SET_BVCASE_DATA', bvcase)
  },
  saveCaseData({ commit, state, rootGetters }, data) {
    state.saving = true
    return axios.post('/caseData', data)
      .then((resp) => {
        commit('UPDATE_CASE_DATA', resp.data)
      });
  },
  saveCandidateCaseData({ commit, state }, data) {
    data = { case_id: data.bvcase.id, records: data.records }
    state.saving = true
    return axios.post('/candidateCaseData', data)
      .then((resp) => {
        commit('UPDATE_CANDIDATE_CASE_DATA', resp.data)
      });
  },
  incrementVersion({ commit, state }) {
    commit('INCREMENT_CASE_VERSION')
    // axios.get('/increment_case_version', {params:params})
    // .then((resp) => {
    // });
  },
  update({ commit, state }, data) {
    commit('UPDATE', data)
  },
  updateRecord({ commit, state }, data) {
    commit('UPDATE_RECORD', data)
  },
  updateStage({ commit, state }, data) {
    commit('UPDATE_STAGE', data)
  }
}
export const namespaced = true;
