<template>
  <card head foot :title="$t('staffHome.admin_tools.staff')" :body="false">
    <data-table :columns="usersTableColumns" :data="users" data-key="users" class="users-table">
      <template #cell-roles="{ rowIndex, rowData, col, error, hasError }">
        <badge :key="role.name + rowIndex" v-for="role in rowData[col.name]" class="ms-2">{{ role.name }}</badge>
      </template>
      <template #cell-training_sessions="{ rowIndex, rowData, col, error, hasError }">
        <div class="m-auto">
          <span v-if="!userHasRole(rowData, 'Trainer')">
            <fa icon="times" />
          </span>
          <badge :key="ts.name + rowIndex" v-for="ts in rowData[col.name]" class="m-1"
            v-popover="{ event: 'hover', title: ts.name, body: ts.title }">{{ ts.id }}</badge>
        </div>
      </template>
      <template #cell-edit="{ rowIndex, rowData, col, error, hasError }">
        <btn @click.native="showEditUserModal(rowData)" size="sm" class="m-auto">
          <fa icon="cog"></fa>
        </btn>
      </template>
    </data-table>
    <template #footer-left>
      <btn @click.native="showNewUserModal" class="float-start m-2">
        Add Staff
      </btn>
    </template>
    <modal :show="editUserModalShow" size="lg" :title="'Edit User: ' + editedUser.name" @save="updateUser(editedUser)"
      @close="editUserModalShow = false">
      <div :class="{ 'was-validated': editedUserValidated }">
        <form-group :di="{ name: 'name', label: 'Name:' }" cols="3|9" :error="editedUserErrors.name">
          <textbox v-model="editedUser.name" />
        </form-group>
        <form-group :di="{ name: 'email', label: 'Email:' }" cols="3|9"
          :error="editedUserErrors.email || editedUserErrors.email_hash">
          <textbox v-model="editedUser.email" />
        </form-group>
        <form-group :di="{ name: 'roles', label: 'Roles:' }" cols="3|9" :error="editedUserErrors.roles">
          <list-group horizontal class="mb-2">
            <list-group-item v-for="role in editedUser.roles" :key="role.name" class=" d-flex flex-nowrap">
              <div>{{ role.name }}</div>
              <button @click="removeRole(editedUser, role)" class="ms-2 me-n2 btn-close"></button>
            </list-group-item>
          </list-group>
          <dropdown :items="availableRoles(editedUser)" :disabled="availableRoles(editedUser).length == 0"
            label-name="name" value-name="id" btnLabel="Add a role" @input="(r) => addRoleToUser(editedUser, r)">
          </dropdown>
        </form-group>
        <div v-if="userHasRole(editedUser, 'Trainer')">
          <hr />
          <form-group :di="{ name: 'training_sessions', label: 'Training Sessions' }" cols="3|9"
            :error="editedUserErrors.training_sessions">
            <list-group horizontal class="mb-2">
              <list-group-item v-for="ts in editedUser.training_sessions" :key="ts.name" class=" d-flex flex-nowrap">
                <div>{{ ts.name }}</div>
                <button @click="removeTrainingSession(editedUser, ts)" class="ms-2 me-n2 btn-close"></button>
              </list-group-item>
            </list-group>
            <dropdown :items="availableTrainingSessions(editedUser)"
              :disabled="availableTrainingSessions(editedUser).length == 0" label-name="name" value-name="id"
              btnLabel="Add a training session" @input="(r) => addTrainingSessionToUser(editedUser, r)"></dropdown>
          </form-group>
        </div>
      </div>
      <template #footer-inner="{ close, save }">
        <btn btn-class="danger" class="me-auto" @click.native="deleteUserConfirm(editedUser)">
          <i class="fa fa-trash-o"></i> Delete
        </btn>
        <btn btn-class="secondary" class="ms-auto" @click.native="close(editedUser)">
          <i class="fa fa-times"></i> Cancel
        </btn>
        <btn btn-class="success" @click.native="updateUser(editedUser, close)">
          <i class="fa fa-save"></i> Save
        </btn>
      </template>
    </modal>
    <modal :show="newUserModalShow" size="lg" :title="'New User: ' + newUser.name" @save="addUser(newUser)"
      @close="newUserModalShow = false">
      <div :class="{ 'was-validated': newUserValidated }">
        <form-group :di="{ name: 'name', label: 'Name:' }" cols="3|9" :error="newUserErrors.name">
          <textbox v-model="newUser.name" />
        </form-group>
        <form-group :di="{ name: 'email', label: 'Email:' }" cols="3|9"
          :error="newUserErrors.email || newUserErrors.email_hash">
          <textbox v-model="newUser.email" />
        </form-group>
        <form-group :di="{ name: 'roles', label: 'Roles:' }" cols="3|9" :error="newUserErrors.roles">
          <list-group horizontal class="mb-2">
            <list-group-item v-for="role in newUser.roles" :key="role.name" class=" d-flex flex-nowrap">
              <div>{{ role.name }}</div>
              <button @click="removeRole(newUser, role)" class="ms-2 me-n2 btn-close"></button>
            </list-group-item>
          </list-group>
          <dropdown :items="availableRoles(newUser)" :disabled="availableRoles(newUser).length == 0" label-name="name"
            value-name="id" btnLabel="Add a role" @input="(r) => addRoleToUser(newUser, r)"></dropdown>
        </form-group>
        <div v-if="userHasRole(newUser, 'Trainer')">
          <hr />
          <form-group :di="{ name: 'training_sessions', label: 'Training Sessions' }" cols="3|9"
            :error="newUserErrors.training_sessions">
            <list-group horizontal class="mb-2">
              <list-group-item v-for="ts in newUser.training_sessions" :key="ts.name" class=" d-flex flex-nowrap">
                <div>{{ ts.name }}</div>
                <button @click="removeTrainingSession(newUser, ts)" class="ms-2 me-n2 btn-close"></button>
              </list-group-item>
            </list-group>
            <dropdown :items="availableTrainingSessions(newUser)"
              :disabled="availableTrainingSessions(newUser).length == 0" label-name="name" value-name="id"
              btnLabel="Add a training session" @input="(r) => addTrainingSessionToUser(newUser, r)"></dropdown>
          </form-group>
        </div>
      </div>
      <template #footer-inner="{ close, save }">
        <btn btn-class="secondary" class="ms-auto" @click.native="close(newUser)">
          <i class="fa fa-times"></i> Cancel
        </btn>
        <btn btn-class="success" @click.native="addUser(newUser, close)">
          <i class="fa fa-save"></i> Save
        </btn>
      </template>
    </modal>
  </card>
</template>
<script>
import Swal from 'sweetalert2'
import { mapActions, mapState, mapGetters } from 'vuex'
import { startCase, toLower, find, findIndex, clone, filter, sortBy } from 'lodash'
export default {
  watch: {
    '$route': {
      handler() {
        this.getStaffData()
      },
      immediate: true
    },
  },
  user: {
    name: '',
    email: '',
    roles: [],
    training_sessions: []
  },
  roles: [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Editor' },
    { id: 4, name: 'Trainer' }
  ],
  data() {
    return {
      editedUser: {},
      editedUserErrors: false,
      editedUserValidated: false,
      newUser: {},
      newUserErrors: false,
      newUserValidated: false,
      newUserModalShow: false,
      editUserModalShow: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('editor', ['users', 'loaded', 'training_sessions']),
    getType() {
      return startCase(toLower(this.$route.params.page))
    },
    usersTableColumns() {
      return [
        { "name": "name", "label": "Name", width: 'max-content' },
        { "name": "email", "label": "Email", width: 'min-content' },
        { "name": "roles", "label": "Roles" },
        { "name": "training_sessions", "label": "Training Sessions", width: 'max-content' },
        { "name": "edit", "label": "Edit", width: 'min-content' },
      ]
    },

  },
  methods: {
    ...mapActions('editor', ['getStaffData', 'addStaffMember', 'updateStaffMember', 'deleteStaffMember']),
    addRoleToUser(user, roleIndex) {
      var role = find(this.$options.roles, (r) => r.id == roleIndex)
      if (!user.roles) user.roles = []
      user.roles.push(role)
    },
    userHasRole(user, roleName) {
      return findIndex(user.roles, (r) => r.name == roleName) > -1;
    },
    availableRoles(user) {
      var allRoles = clone(this.$options.roles)
      return filter(allRoles, (r) => {
        return findIndex(user.roles, (ur) => {
          return ur.name == r.name
        }) == -1
      })
    },
    removeRole(user, role) {
      var index = findIndex(user.roles, (r) => r.id == role.id);
      user.roles.splice(index, 1)
    },
    availableTrainingSessions(user) {
      return filter(this.training_sessions, (ats) => {
        return findIndex(user.training_sessions, (ts) => {
          return ts.id == ats.id
        }) == -1
      })
    },
    addTrainingSessionToUser(user, tsId) {
      var ts = find(this.training_sessions, (ts) => ts.id == tsId)
      if (!user.training_sessions) user.training_sessions = []
      user.training_sessions.push(ts)
    },
    removeTrainingSession(user, training_session) {
      var index = findIndex(user.training_sessions, (r) => r.id == training_session.id);
      user.training_sessions.splice(index, 1)
    },
    showNewUserModal() {
      this.newUser = clone(this.$options.user)
      this.newUserErrors = []
      this.newUserModalShow = true
    },
    showEditUserModal(user) {
      this.editedUser = clone(user)
      this.editedUser.roles = clone(user.roles)
      this.editedUserErrors = []
      this.editUserModalShow = true
    },
    addUser(user) {
      this.addStaffMember(user)
        .then(resp => {
          this.newUserErrors = false
          this.newUserModalShow = false
          close()

        })
        .catch(err => {
          this.newUserErrors = err.response.data.errors
        })
        .then(resp => {
          this.newUserValidated = true
        })
    },
    updateUser(user, close) {
      this.updateStaffMember(user)
        .then(resp => {
          // console.log('resp')
          this.editedUserErrors = false
          this.editUserModalShow = false
          close()

        })
        .catch(err => {
          // console.log('err')
          this.editedUserErrors = err.response.data.errors
        })
        .then(resp => {
          this.editedUserValidated = true
        })
    },
    deleteUser(user) {
      // console.log('deleteUser', user)
      this.deleteStaffMember(user)
        .then(resp => {
          this.editedUserErrors = false
          this.editUserModalShow = false
          this.getStaffData()
        })
        .catch(err => {
          this.editedUserErrors = err.response.data.errors
        })
        .then(resp => {
          this.editedUserValidated = true
        })
    },
    deleteUserConfirm(user) {
      Swal.fire({
        icon: 'warning',
        showCancelButton: true,
        title: this.$t('staffHome.admin_tools.deleteUserConfirmTitle'),
        text: this.$t('staffHome.admin_tools.deleteUserConfirmText'),
        confirmButtonText: this.$t('ok'),
        cancelButtonText: this.$t('cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteUser(user)
        }
      })
    },
    sortedRoles(roles) {
      return sortBy(roles, (role) => { return role.name })
    }
  }
}

</script>
<style>
.users-table .datatable div.dt-row {
  grid-template-columns: [index] 30px [name] 1fr [email] 2fr [roles] 1.2fr [training_session] 1fr [edit] 40px;
}
</style>
