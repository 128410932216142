<template>
  <div class="container">
    <card title="Learning Resources" head foot>
      <h1>BVAS VDI Presentation</h1>
      <row>
        <column :sizes="{ md: 8 }">
          <iframe width="100%" height="410"
            src="https://www.youtube.com/embed/videoseries?list=PLUs-wXXt3J8F0POhuxTdrtQmHqTOBmpNo&amp;ecver=1"
            frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </column>
        <column :sizes="{ md: 4 }">
          To select different sections of this video please click the 'playlist' button on the video (top left).
        </column>
      </row>
      <hr>
      <h1>BVAS Demonstration</h1>
      <row>
        <column :sizes="{ md: 8 }">
          <youtube style="width:100%" :video-id="videoId('bvas')" ref="bvas" :player-vars="playerVars"
            @playing="(e) => playing(e, 'bvas')" @paused="paused" @ended="ended"></youtube>
        </column>
        <column :sizes="{ md: 4 }">
          <h3>Contents:</h3>
          <list-group>
            <list-group-item v-for="timepoint in bvas" @click.native="jumpToTime('bvas', timepoint.from)"
              :key="timepoint.from" :class="{ 'active': isCurrentChapter(timepoint.from, timepoint.till) }"
              style="cursor:pointer;">{{ timepoint.title }}: <small>{{ timepoint.from }}</small>
            </list-group-item>
          </list-group>
        </column>
      </row>
      <hr>
      <h1>VDI Demonstration</h1>
      <row>
        <column :sizes="{ md: 8 }">
          <youtube style="width:100%" :video-id="videoId('vdi')" ref="vdi" :player-vars="playerVars"
            @playing="(e) => playing(e, 'vdi')">
          </youtube>
        </column>
        <column :sizes="{ md: 4 }">
          <h3>Contents:</h3>
          <list-group>
            <list-group-item v-for="timepoint in vdi" @click.native="jumpToTime('vdi', timepoint.from)"
              :key="timepoint.from" :class="{ 'active': isCurrentChapter(timepoint.from, timepoint.till) }"
              style="cursor:pointer;">{{ timepoint.title }}: <small>{{ timepoint.from }}</small>
            </list-group-item>
          </list-group>
        </column>
      </row>
    </card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentTime: '0:00',
      currentVideo: null,
      playerVars: {
        origin: window.config.base_url
      },
      bvas: [
        { title: 'Introduction', from: '0:01', till: '0:50' },
        { title: 'BVAS Intro', from: '0:50', till: '4:46' },
        { title: 'BVAS Form: example completion', from: '4:46', till: '6:34' },
        { title: 'BVAS Form: order', from: '6:34', till: '9:51' },
        { title: 'BVAS Form: none boxes', from: '9:51', till: '10:39' },
        { title: 'BVAS Form: persistent disease', from: '10:39', till: '13:55' },
        { title: 'BVAS consultation: intro', from: '13:55', till: '15:19' },
        { title: 'BVAS consultation: general', from: '15:19', till: '16:41' },
        { title: 'BVAS consultation: skin', from: '16:41', till: '17:46' },
        { title: 'BVAS consultation: Mucous membranes', from: '17:46', till: '19:17' },
        { title: 'BVAS consultation: ENT', from: '19:17', till: '20:54' },
        { title: 'BVAS consultation: Chest', from: '20:54', till: '23:57' },
        { title: 'BVAS consultation: Cardiovascular', from: '23:57', till: '26:35' },
        { title: 'BVAS consultation: Abdominal', from: '26:35', till: '27:15' },
        { title: 'BVAS consultation: Renal', from: '27:15', till: '29:46' },
        { title: 'BVAS consultation: Nervous system', from: '29:46', till: '31:28' },
        { title: 'BVAS consultation: Other', from: '31:28', till: '32:06' },
        { title: 'BVAS scoring: online calculator', from: '32:06', till: '35:42' },
        { title: 'BVAS consultation 2: intro', from: '35:42', till: '36:18' },
        { title: 'BVAS consultation 2: general', from: '36:18', till: '38:25' },
        { title: 'BVAS consultation 2: skin', from: '38:25', till: '39:56' },
        { title: 'BVAS consultation 2: Mucous membranes', from: '39:56', till: '41:50' },
        { title: 'BVAS consultation 2: ENT', from: '41:50', till: '42:37' },
        { title: 'BVAS consultation 2: Chest & cardiovascular', from: '42:37', till: '45:22' },
        { title: 'BVAS consultation 2: Abdominal', from: '45:22', till: '46:21' },
        { title: 'BVAS consultation 2: Renal', from: '46:21', till: '47:21' },
        { title: 'BVAS consultation 2: Nervous system', from: '47:21', till: '48:46' },
        { title: 'BVAS consultation 2: Other ', from: '48:46', till: '50:00' },
      ],
      vdi: [
        { title: 'VDI: Intro', from: '0:01', till: '3:36' },
        { title: 'VDI Form: example completion', from: '3:36', till: '6:41' },
        { title: 'VDI Form: scoring', from: '6:41', till: '7:13' },
        { title: 'VDI: Through time', from: '7:13', till: '9:24' },
        { title: 'VDI consultation 1', from: '9:24', till: '10:03' },
        { title: 'VDI consultation 2: Musculoskeletal', from: '10:03', till: '10:44' },
        { title: 'VDI consultation 2: Skin/mucous membranes', from: '10:44', till: '11:55' },
        { title: 'VDI consultation 2: Ocular', from: '11:55', till: '12:33' },
        { title: 'VDI consultation 2: ENT & pulmonary', from: '12:33', till: '13:54' },
        { title: 'VDI consultation 2: Cardiovascular', from: '13:54', till: '14:30' },
        { title: 'VDI consultation 2: Peripheral vascular disease', from: '14:30', till: '15:02' },
        { title: 'VDI consultation 2: Gastrointestinal', from: '15:02', till: '15:28' },
        { title: 'VDI consultation 2: Renal', from: '15:28', till: '16:02' },
        { title: 'VDI consultation 2: Neuropsychiatric', from: '16:02', till: '16:55' },
        { title: 'VDI consultation 2: Other', from: '16:55', till: '19:44' },
        { title: 'VDI consultation 2: outro', from: '19:44', till: '21:13' },
        { title: 'Outro: BVAS & VDI summary', from: '21:13', till: '24:13' },
      ],
    }
  },
  computed: {
    bvas_player() {
      return this.$refs.bvas.player
    },
    vdi_player() {
      return this.$refs.vdi.player
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    videoId(type) {
      if (type == 'bvas') return 'ZRiqk86rFQ4'
      if (type == 'vdi') return 'u3fB2GYtwNU'
    },
    videoById(id) {

      return {
        'ZRiqk86rFQ4': 'bvas',
        'u3fB2GYtwNU': 'vdi'
      }[id]
    },
    jumpToTime(video, time) {
      time = this.timeInSecs(time)
      this[video + '_player'].cueVideoById({
        'videoId': this.videoId(video),
        'startSeconds': time,
        // autoplay: true
      })
      this.playVideo(video)
    },
    playVideo(video) {
      this[video + '_player'].playVideo()
    },
    playing(e, id) {
      this.currentVideo = id
      this.interval = setInterval(function () {
        this.setCurrentTime(this.currentVideo)
      }.bind(this), 2000);
    },
    paused(e) {
      clearInterval(this.interval)
    },
    ended(e) {
      clearInterval(this.interval)
    },
    setCurrentTime(video) {
      var promise = Promise.resolve(this[video + '_player'].getCurrentTime());
      promise.then((s) => {
        this.currentTime = this.timeString(s)
      })

    },
    timeString(time) {
      var mind = time % (60 * 60);
      var minutes = Math.floor(mind / 60);
      var secd = mind % 60;
      var seconds = Math.ceil(secd);
      return minutes + ':' + seconds
    },
    timeInSecs(timeString) {
      if (timeString) {
        var arr = timeString.split(':')
        return (parseInt(arr[0]) * 60) + parseInt(arr[1])
      }
    },
    isCurrentChapter(from, till) {
      var ct = this.timeInSecs(this.currentTime)
      from = this.timeInSecs(from)
      till = this.timeInSecs(till)
      if (ct > from && ct < till) {
        return true
      }
    }
  }
}
</script>