<template>
  <form @submit.prevent="update" @keydown="form.onKeydown($event)">
    <card head foot :title="$t('settings.your_info')" :class="{ 'was-validated': validated }">
      <alert type="success" v-if="alert_displayed" dismissable @dismiss="alert_displayed = false">
        <span v-text="$t('settings.info_updated')"></span>
      </alert>
      <!-- Name -->
      <form-group :di="{ name: 'name', label: $t('settings.name') }" cols="3|7" :error="errors.name">
        <textbox name="name" v-model="form.name" />
      </form-group>
      <!-- Email -->
      <form-group :di="{ name: 'email', label: $t('settings.email') }" cols="3|7"
        :error="errors.email || errors.email_hash">
        <textbox name="email" type="email" v-model="form.email" />
      </form-group>
      <form-group :di="{ name: 'role', label: $t('settings.role') }" cols="3|7" :error="errors.role">
        <badge class="me-2" v-for="r in user.roles" :key="r.name" v-text="r.name"></badge>
      </form-group>
      </div>
      <!-- Submit Button -->
      <div slot="footer" class="card-footer">
        <div class="col-md-9 ms-md-auto">
          <button class="btn btn-primary" type="submit" :class="[{ 'btn-loading': form.busy }]">
            {{ $t('settings.update') }}
          </button>
        </div>
      </div>
    </card>
  </form>
</template>
<script>
import Form from 'vform'
import { mapGetters } from 'vuex'

export default {
  scrollToTop: false,

  metaInfo() {
    return { title: this.$t('settings.title') }
  },

  data: () => ({
    alert_displayed: false,
    validated: false,
    form: new Form({
      name: '',
      email: ''
    })
  }),

  computed: {
    ...mapGetters('auth', ['user']),
    errors() {
      if (this.form.errors) return this.form.errors.errors
      return {}
    }
  },

  created() {
    // Fill the form with user data.
    this.form.keys().forEach(key => {
      this.form[key] = this.user[key]
    })
  },

  methods: {
    async update() {
      await this.form.patch('/settings/account')
        .catch((err) => {
          this.validated = true

        })
        .then((resp) => {
          if (resp) {
            this.alert_displayed = true
            var user = resp.data
            this.$store.dispatch('auth/updateUser', { user })
            this.validated = true
          }
        })
    }
  }
}

</script>
