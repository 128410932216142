<template>
  <div :class="{ 'container-xxl': $route.name == 'cases' && !routeChanging }" style="transition:all .3s ease;">
    <row class="g-2">
      <column :sizes="{ md: $route.name == 'cases' ? 12 : 8 }" :key="1" style="transition:all .3s ease;">
        <card :title="$t('staffHome.cases.title')" head :body="false" foot style="transition:all .3s ease;">
          <template #header>
            <div class="card-header p-1 d-flex align-items-center">
              <h4 class="m-0 ms-2 flex-grow-1" v-text="$t('staffHome.cases.title')"></h4>
              <btn size="sm" btn-class="outline-primary" class="mx-2" @click.native="toggleFilterRow">
                <fa icon="filter" />
              </btn>
              <router-link class="btn btn-sm btn-primary p-1" :to="{ name: 'new_case' }"
                v-t="{ path: 'staffHome.cases.new_case' }" />
              <router-link v-if="$route.name == 'cases'" :class="['btn btn-sm p-1 ms-2', 'btn-secondary']"
                :to="{ name: 'collections' }" v-t="{ path: 'staffHome.cases.collections.title' }" />
            </div>
          </template>
          <div class="cases-list-container">
            <table id="case_table" class="cases-table table table-hover table-bordered table-sm mb-0">
              <thead v-if="ui.filterRowVisible" class="text-center">
                <tr>
                  <td class="p-0"></td>
                  <td class="p-1">
                    <textbox v-model="ui.filter.name" size="sm" placeholder="Search:"></textbox>
                  </td>
                  <td class="p-1">
                    <dropdown v-if="!hasFilter('diagnosis')" btn-class="primary btn-sm p-1" btn-label="" size="sm"
                      v-model="ui.filter.diagnosis" :items="fullDiagnosesList">
                      <template #btn-inner="{ chosenLabel }">
                        <div v-html="chosenLabel"></div>
                      </template>
                      <template #list="{ item, selectItem }">
                        <button :id="'filter.diagnosis_' + item.value" :key="item.value"
                          :class="['dropdown-item', { 'active': item.value == ui.filter.diagnosis }]"
                          @click="selectItem(item)" :value="item.value">
                          <span v-html="item.label"></span>
                        </button>
                      </template>
                    </dropdown>
                    <input-group v-else size="sm">
                      <div class="form-control d-flex">
                        <fa icon="filter" size="sm" class="m-1" />
                        {{ ui.filter.diagnosis }}
                        <btn size="sm" @click.native="resetFilter('diagnosis')" class="btn-close ms-auto"></btn>
                      </div>
                    </input-group>
                  </td>
                  <td class="p-1">
                    <dropdown v-if="!hasFilter('case_type')" btn-class="primary btn-sm p-1" size="sm" btn-label=""
                      v-model="ui.filter.case_type"
                      :items="[{ label: 'assessment', value: 'assessment' }, { label: 'training', value: 'training' }]">
                    </dropdown>
                    <input-group v-else size="sm">
                      <div class="form-control d-flex">
                        <fa icon="filter" size="sm" class="m-1" />
                        {{ ui.filter.case_type }}
                        <btn size="sm" @click.native="resetFilter('case_type')" class="btn-close ms-auto"></btn>
                      </div>
                    </input-group>
                  </td>
                  <td class="p-1">
                    <dropdown v-if="!hasFilter('questionnaire_type')" btn-label="" btn-class="primary btn-sm p-1"
                      v-model="ui.filter.questionnaire_type"
                      :items="[{ label: 'bvas', value: 'bvas' }, { label: 'vdi', value: 'vdi' }, { label: 'pvas', value: 'pvas' }, { label: 'pvdi', value: 'pvdi' }]">
                    </dropdown>
                    <input-group v-else size="sm">
                      <div class="form-control d-flex">
                        <fa icon="filter" size="sm" class="m-1" />
                        {{ ui.filter.questionnaire_type }}
                        <btn size="sm" @click.native="resetFilter('questionnaire_type')" class="btn-close ms-auto">
                        </btn>
                      </div>
                    </input-group>
                  </td>
                  <td class="p-1">
                    <dropdown v-if="!hasFilter('difficulty')" btn-label="" btn-class="primary btn-sm p-1"
                      v-model="ui.filter.difficulty"
                      :items="[{ label: 'Gold', value: 'Gold' }, { label: 'Silver', value: 'Silver' }, { label: 'Bronze', value: 'Bronze' }]">
                    </dropdown>
                    <input-group v-else size="sm">
                      <div class="form-control d-flex">
                        <fa icon="filter" size="sm" class="m-1" />
                        {{ ui.filter.difficulty }}
                        <btn size="sm" @click.native="resetFilter('difficulty')" class="btn-close ms-auto"></btn>
                      </div>
                    </input-group>
                  </td>
                  <td class="p-1">
                    <btn size="sm" v-model="ui.filter.review1" :true-value="1" :false-value="null" btn-class="secondary">
                      <fa :icon="ui.filter.review1 ? 'check-square' : 'square'"></fa>
                    </btn>
                  </td>
                  <td class="p-1">
                    <btn size="sm" v-model="ui.filter.review2" :true-value="1" :false-value="null" btn-class="secondary"
                      class="p-1">
                      <fa :icon="ui.filter.review2 ? 'check-square' : 'square'"></fa>
                    </btn>
                  </td>
                  <td class="p-1">
                    <dropdown v-if="!hasFilter('locked')" btn-label="" btn-class="primary btn-sm p-1"
                      v-model="ui.filter.locked"
                      :items="[{ label: 'locked', value: 1 }, { label: 'unlocked', value: 2 }]">
                    </dropdown>
                    <input-group v-else size="sm">
                      <div class="form-control d-flex">
                        <fa icon="filter" size="sm" class="m-1" />
                        {{ ui.filter.locked == 1 ? 'Locked' : 'Unlocked' }}
                        <btn size="sm" @click.native="resetFilter('locked')" class="btn-close ms-auto"></btn>
                      </div>
                    </input-group>
                  </td>
                  <td class="p-1">
                    <dropdown v-if="!hasFilter('case_status')" btn-label="" btn-class="primary btn-sm p-1"
                      v-model="ui.filter.case_status"
                      :items="[{ label: 'Active', value: 1 }, { label: 'Archived', value: 2 }]">
                    </dropdown>
                    <input-group v-else size="sm">
                      <div class="form-control d-flex">
                        <fa icon="filter" size="sm" class="m-1" />
                        {{ ui.filter.case_status == 1 ? 'Active' : 'Archive' }}
                        <btn size="sm" @click.native="resetFilter('case_status')" class="btn-close ms-auto"></btn>
                      </div>
                    </input-group>
                  </td>
                </tr>
              </thead>
              <thead>
                <tr class="text-center">
                  <th v-for="col in ui.columns" @click="sortByColumn(col)"
                    :class="[col, 'p-1 text-sm sortable', { 'sorted-by': col == ui.pagination.sort_by }]">
                    <div class="d-flex">
                      <small class="flex-grow-1 text-center" v-text="columnLabel(col)"></small>
                      <fa class="ms-auto" v-if="col == ui.pagination.sort_by"
                        :icon="ui.pagination.sort_dir == 1 ? 'chevron-up' : 'chevron-down'"></fa>
                    </div>
                  </th>
                  <th class="p-1 text-center"> <small>View</small> </th>
                </tr>
              </thead>
              <transition-group tag="tbody" name="fade" mode="out-in">
                <tr v-for="(bvcase, n) in paginated_cases" :key="'row' + n"
                  :class="['align-middle align-items-center', { 'previous_case': previous_case == bvcase.id }]"
                  @mouseover="setHoverRow(bvcase)" @mouseout="clearHoverRow(bvcase)">
                  <td v-for="col in ui.columns" class="text-center">
                    <div v-if="col == 'difficulty'" class="d-flex">
                      <div v-if="bvcase[col]" :class="'medal medal-' + bvcase[col].toLowerCase()"></div>
                      <div v-text="bvcase[col]" class="flex-grow-1"></div>
                    </div>
                    <fa v-if="col == 'locked'" :icon="bvcase[col] == 1 ? 'lock' : 'lock-open'" size="lg">
                    </fa>
                    <fa v-if="col == 'review1'" :icon="bvcase.reviewer1_id != null ? 'check-square' : 'square'" size="lg">
                    </fa>
                    <fa v-if="col == 'review2'" :icon="bvcase.reviewer2_id != null ? 'check-square' : 'square'" size="lg">
                    </fa>
                    <div v-if="bvcase[col] && col != 'difficulty' && col != 'locked' && col != 'case_status'"
                      v-text="bvcase[col]">
                    </div>
                    <div v-if="col == 'case_status'" v-text="caseStatus(bvcase.case_status)"></div>
                  </td>
                  <td class="text-center p-0  px-1">
                    <btn outline is-router-link size="sm" block class="p-0"
                      :to="{ name: 'edit_case', params: { case_id: bvcase.id } }">
                      <fa icon="chevron-right"></fa>
                    </btn>
                  </td>
                  <td class="text-center p-0 px-1"
                    v-if="$route.name == 'edit_collection' || $route.name == 'new_collection'">
                    <btn outline block class="p-0" size="sm" @click.native="addCaseToCollection(bvcase)">
                      <fa icon="plus" />
                    </btn>
                  </td>
                </tr>
              </transition-group>
            </table>
          </div>
          <template #footer-inner>
            <div class="d-flex">
              <btn outline size="sm" @click.native="prevPage" :disabled="ui.pagination.current == 1" class="me-auto p-1">
                <fa icon="arrow-circle-left" size="lg" />
              </btn>
              <div class="m-auto">
                <ul class="pagination pagination-sm m-1">
                  <li v-for="no in ui.pagination.total_pages"
                    :class="['page-item', { active: ui.pagination.current == no }]">
                    <a @click="ui.pagination.current = no" class="page-link" v-html="no" />
                  </li>
                </ul>
              </div>
              <btn outline size="sm" @click.native="nextPage"
                :disabled="ui.pagination.current == ui.pagination.total_pages" class="ms-auto p-1">
                <fa icon="arrow-circle-right" size="xl" />
              </btn>
            </div>
            <div class="text-center">{{ ui.pagination.current }} of {{ ui.pagination.total_pages }}</div>
          </template>
        </card>
      </column>
      <column :key="2" v-if="$route.name != 'cases'">
        <case-collections v-if="$route.name == 'collections'">
        </case-collections>
        <edit-case-collection v-if="($route.name == 'edit_collection' || $route.name == 'new_collection')">
        </edit-case-collection>
        <fa v-else-if="routeChanging" icon="cog" spin />
      </column>
    </row>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CaseCollections from './partials/CaseCollections.vue'
import EditCaseCollection from './partials/EditCaseCollection.vue'
import { clone, take, drop, sortBy, reverse, filter, includes, map } from 'lodash'
export default {
  middleware: 'role:Admin,Editor',
  layout: 'fullscreen',
  components: {
    CaseCollections,
    EditCaseCollection
  },
  watch: {
    '$route': {
      handler() {

        if (!this.casesLoaded) {
          this.routeChanging = true;
          this.getCaseList().then(() => {
            this.casesLoaded = true;
            this.routeChanging = false;
          })
        }

        if (this.$route.name == 'collections') {
          this.routeChanging = true;
          this.getCaseCollections({ params: this.$route.params }).then(() => { this.routeChanging = false; })
        }
        if (this.$route.name == 'edit_collection') {
          this.routeChanging = true;
          this.getCaseCollection({ params: this.$route.params }).then(() => { this.routeChanging = false; })
        }
        if (this.$route.name == 'new_collection') {
          this.routeChanging = true;
          this.update({ collection: clone(this.$options.empty_collection) }).then(() => { this.routeChanging = false; })
        }

      },
      immediate: true
    },
    filtered_cases() {
      this.ui.pagination.current = 1
    },

  },
  empty_collection: {
    name: '',
    title: '',
    description: '',
    cases: [],
    reserve_cases: [],
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name == 'edit_case') {
        vm.previous_case = from.params.case_id
      }
      if (to.name == 'cases') vm.clearBVCaseData()
    })
  },
  data() {
    return {
      casesLoaded: false,
      routeChanging: false,
      previous_case: null,
      collectionErrors: [],
      tinymce_settings: {
        browser_spellcheck: true,
        min_height: 600,
        skin_url: '/css',
        inline: true,
        branding: false,
        block_formats: "Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6;"
      }
    }
  },
  computed: {
    ...mapState('editor', [
      'cases', 'collections', 'collection', 'loaded', 'mode', 'records', 'diagnoses'
    ]),
    ...mapState('editor', { ui: state => state.ui.cases }),
    paginationOffset() {
      var p = this.ui.pagination
      return (p.current - 1) * p.per_page
    },
    paginated_cases() {
      var p = this.ui.pagination
      p.total_pages = Math.ceil(this.sorted_cases.length / p.per_page)
      return take(drop(this.sorted_cases, this.paginationOffset), p.per_page);
    },
    sorted_cases() {
      var p = this.ui.pagination
      var sb = p.sort_by
      var sbArr = ['Bronze', 'Silver', 'Gold'];
      var cases = this.$route.name == 'edit_collection' || this.$route.name == 'new_collection' ? this.casesNotInCollection : this.filtered_cases;
      var sorted = sortBy(cases, (bvcase) => {
        var val = bvcase[sb]
        if (sb == 'difficulty') { val = sbArr.indexOf(val) }
        if (sb == 'review1') { val = bvcase.reviewer1_id != null }
        if (sb == 'review2') { val = bvcase.reviewer2_id != null }
        return val;
      });
      if (p.sort_dir == -1) reverse(sorted);
      return sorted
    },
    filtered_cases() {
      var filters = this.ui.filter
      // console.log(this.cases);
      // return this.cases
      return filter(this.cases, (bvcase) => {
        var resp = true;
        if (bvcase.name && filters.name != null && resp) { resp = includes(bvcase.name.toLowerCase(), filters.name.toLowerCase()) }
        if (filters.diagnosis != null && resp) { resp = bvcase.diagnosis == filters.diagnosis }
        if (filters.case_type != null && resp) { resp = bvcase.case_type == filters.case_type }
        if (filters.questionnaire_type != null && resp) { resp = bvcase.questionnaire_type == filters.questionnaire_type }
        if (filters.difficulty != null && resp) { resp = bvcase.difficulty == filters.difficulty }
        if (filters.review1 == 1 && resp) { resp = bvcase.reviewer1_id != null }
        if (filters.review2 == 1 && resp) { resp = bvcase.reviewer2_id != null }
        if (filters.locked != null && resp) {
          if (filters.locked == 1) resp = bvcase.locked == 1
          if (filters.locked == 2) resp = bvcase.locked == null
        }
        if (filters.case_status != null && resp) {
          resp = bvcase.case_status == filters.case_status;
          // console.log(bvcase.case_status, filters.case_status)
        }

        return resp
      })
    },
    casesNotInCollection() {
      if (this.collection && this.collection.cases) {
        var caseIds = map(this.collection.cases, (bvcase) => bvcase.id).concat(map(this.collection.reserve_cases, (bvcase) => bvcase.id))
        return filter(this.filtered_cases, (bvcase) => {
          return !includes(caseIds, bvcase.id)
        })
      } else {
        return this.filtered_cases
      }
    },
    fullDiagnosesList() {
      var diagnoses = this.diagnoses.map((d, i) => {
        var total = filter(this.filtered_cases, (bvcase, i) => {
          return bvcase.diagnosis == d.value
        }).length
        var label = d.label + ' <span class="badge bg-secondary">' + total + '</span>';
        return { label, value: d.value }
      })
      return diagnoses
    },
    diagnosesListed() {
      var diagnoses = filter(this.diagnoses, (d, i) => {
        var total = filter(this.filtered_cases, (bvcase, i) => {
          return bvcase.diagnosis == d.value
        }).length
        return (total > 0)
      }).map((d, i) => {
        var total = filter(this.filtered_cases, (bvcase, i) => {
          return bvcase.diagnosis == d.value
        }).length
        var label = d.label + ' <span class="badge bg-secondary">' + total + '</span>';
        return { label, value: d.value }
      })
      return diagnoses
    }
  },
  methods: {
    ...mapActions('editor', ['getCaseList', 'getCaseCollection', 'getCaseCollections', 'clearBVCaseData', 'update']),
    columnLabel(col) {
      var cols = {
        id: 'ID',
        name: 'Name',
        diagnosis: 'Diagnosis',
        case_type: 'Case Type',
        questionnaire_type: 'Q Type',
        difficulty: 'Difficulty',
        review1: '1st Rev',
        review2: '2nd Rev',
        locked: 'Locked',
        case_status: 'Status',
      }
      return cols[col]
    },
    setHoverRow(bvcase) {
      document.querySelectorAll('[case-id="' + bvcase.id + '"]').forEach((el) => { el.classList.add('active') });
    },
    clearHoverRow(bvcase) {
      document.querySelectorAll('[case-id="' + bvcase.id + '"]').forEach((el) => { el.classList.remove('active') });
    },
    addCaseToCollection(bvcase) {
      var collCase = { id: bvcase.id, case_id: bvcase.case_id, name: bvcase.name, pivot: { case_id: bvcase.id, collection_id: this.collection.id } }
      this.collection.cases.push(collCase);
    },
    prevPage() {
      if (this.ui.pagination.current >= 2) this.ui.pagination.current--
    },
    nextPage() {
      if (this.ui.pagination.current < this.ui.pagination.total_pages) this.ui.pagination.current++
    },
    sortByColumn(col) {
      if (this.ui.pagination.sort_by == col) {
        this.ui.pagination.sort_dir = this.ui.pagination.sort_dir * -1
      }
      this.ui.pagination.sort_by = col
      this.sorted_cases;
    },
    toggleFilterRow() {
      this.ui.filterRowVisible = !this.ui.filterRowVisible
    },
    resetFilter(field) {
      this.ui.filter[field] = null
    },
    hasFilter(field) {
      return this.ui.filter[field] != null
    },
    caseStatus(case_status) {
      if (case_status == 1) return 'Active'
      if (case_status == 2) return 'Archived'
    }
  }
}

</script>
<style lang="scss">
#case_table {
  tr.previous_case {
    box-shadow: inset 0 0 16px #3097d1;
    transition: all 1s;
  }

  th.locked.sortable {
    width: 60px;
  }
}
</style>
