export default {
	computed:{
        isTrainingCase () {
            return this.bvcase.case_type == 'training';
        },
        currentStage () {
            if(this.stage && this.isTrainingCase && this.bvcase.training_cases[this.stage - 1]){
                return this.bvcase.training_cases[this.stage - 1]
            }
            return this.$parent.currentStage
        },
        currentStageSelected () {
            return this.currentStage !== null && typeof this.currentStage != 'undefined'
        },
        stageIsMarked () {
            if(!this.candidate_case){
                var cc = this.$parent.candidate_case
            }else{
                var cc = this.candidate_case
            }
            return this.currentStageSelected
            && cc
            && cc.json
            && cc.json[this.stage] ? true:false
        },
        stageScorePercent () {
            if(this.stageIsMarked){
                return this.candidate_case.json[this.stage].percent
            }
            return false
        },
    },
    methods:{
        addStage () {
            if(!this.bvcase.training_cases) this.$set(this.bvcase, 'training_cases', []);
            var tc = this.bvcase.training_cases
            tc.push({question:' ', json:{}});
            if(this.$route.name == 'edit_case' || this.$route.name == 'edit_training_case'){
                var route = {name:'edit_training_case', params: {case_id: this.$route.params.case_id, stage: tc.length}}
                this.$router.push(route)
            }
            // this.stage = tc.length
        },
        markStage () {
            if(this.candidate_case && this.candidate_case.json == null) this.$set(this.candidate_case,'json',{})
            var scoredSections = _.transform(this.sections, (r, v, k)=> r[k] = this.scoreSection(k))
            var percent = _.reduce(scoredSections, (r, v, k)=> r += v)
            this.$set(this.candidate_case.json,this.stage,{ scoredSections, percent })
        },
        isFaded (section) {
            if(this.mode == 'editor' && this.currentStageSelected){
                return !this.currentStage.json[section]
            }
            return false
        },
        hiddenForCandidates (section) {
            if(this.mode == 'candidate' && this.currentStageSelected){
                return !this.currentStage.json[section]
            }
            return false
        },
        inStageErrors (field) {
            if(this.stageIsMarked){
                return _.indexOf(this.markedErrors, field.name) != -1
            }
        }
       
    }
}