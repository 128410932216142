import { filter, find, forEach } from 'lodash'
export default {
  mounted() {
    this.$emit('update-groups', this.groups)
  },
  methods: {
    hasCommonError(field) {
      var type = this.bvcase.questionnaire_type
      return filter(this.bvcase[type].common_errors, { dataitem_name: field }).length > 0;
    },
    getCommonError(field) {
      var type = this.bvcase.questionnaire_type
      return find(this.bvcase[type].common_errors, { dataitem_name: field }).message;
    }
    // checkCommonErrors () {
    //     var type = this.bvcase.questionnaire_type
    //     forEach(this.bvcase[type].common_errors, (error)=>{
    //         var di_name = error.dataitem_name
    //         var ref = this.$refs[di_name][0]
    //         var el = ref.$el
    //         $(el).popover({
    //             container: 'body',
    //             content: error.message,
    //             placement: 'auto',
    //             template: '<div class="popover popover-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
    //         })
    //         console.log('checkCommonErrors', this.records[di_name],error.error_value);
    //         if(this.records[di_name] == error.error_value){
    //             $(el).popover('show');
    //         }else{
    //             $(el).popover('hide');
    //         }
    //     })
    // },
  }
}
