import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// Load store modules dynamically.
const modules = {}
const modulesImport = import.meta.globEager('./modules/**/*.js')
for (const path in modulesImport) {
  var mod = modulesImport[path]
    var newPath = path.replace('./modules/', '').replace(/(^.\/)|(\.js$)/g, '')
    modules[newPath] = mod
}
// const modules = requireContext.keys()
//   .map(file =>
//     [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
//   )
//   .reduce((modules, [name, module]) => {
//     if (module.namespaced === undefined) {
//       module.namespaced = true
//     }

//     return { ...modules, [name]: module }
//   }, {})

export default new Vuex.Store({
  modules
})
