<template>
  <card :title="title" head :body="false" foot>
    <router-link :class="['btn btn-close']" slot="header-right" :to="{ name: 'collections' }" aria-label="Close" />
    <div class="card-body was-validated">
      <div>
        <form-group :di="{ name: 'name', label: 'Name:' }" cols="3|9" :errors="collectionErrors">
          <textbox id="coll-name" v-model="collection.name" />
        </form-group>
        <form-group id="title" :di="{ name: 'title', label: 'Title:' }" cols="3|9" :errors="collectionErrors">
          <textbox v-model="collection.title" />
        </form-group>
        <form-group :di="{ name: 'description', label: 'Description:' }" :errors="collectionErrors">
          <prosemirror-editor v-model="collection.description"></prosemirror-editor>
        </form-group>
      </div>
    </div>
    <card title="Cases:" head :body="false" class="m-2">
      <template #header-right>
        <span v-if="collectionsCases" v-html="'Total:<b>' + collectionsCases.length + '</b>'" />
      </template>
      <draggable v-if="collectionsCases" v-model="collection.cases" group="cases" ghost-class="ghost"
        class="list-group-flush">
        <list-group-item v-for="bvcase in collection.cases" :key="bvcase.id" class="d-flex p-1 align-items-center">
          <span class="flex-grow-1">{{ bvcase.id }}: {{ bvcase.name }}</span>
          <!-- <span>{{bvcase.case_status}}</span> -->
          <div class="me-1">
            <div v-if="getCase(bvcase.id) && getCase(bvcase.id).difficulty"
              :class="'m-0 medal medal-' + getCase(bvcase.id).difficulty.toLowerCase()"></div>
          </div>
          <div v-if="!getCase(bvcase.id)" class="me-1">
            <fa icon="trash" class="text-danger" />
          </div>
          <div class=" me-1">
            <btn size="sm" is-router-link :to="{ name: 'edit_case', params: { case_id: bvcase.id } }">
              <fa icon="chevron-right"></fa>
            </btn>
          </div>
          <button type="button" class="btn-close ms-2 me-n1" aria-label="Close"
            @click="removeCaseFromGroup(bvcase, 'cases')"></button>
        </list-group-item>
      </draggable>
    </card>
    <card title="Reserve Cases:" head :body="false" class="m-2">
      <template #header-right>
        <span v-if="collectionsReserveCases" v-html="'Total:<b>' + collectionsReserveCases.length + '</b>'" />
      </template>
      <draggable v-if="collectionsReserveCases" v-model="collection.reserve_cases" group="cases" ghost-class="ghost"
        class="list-group-flush">
        <list-group-item v-for="bvcase in collection.reserve_cases" :key="bvcase.id"
          class="d-flex p-1 align-items-center">
          <span class="flex-grow-1">{{ bvcase.id }}: {{ bvcase.name }}</span>
          <!-- <span>{{bvcase.case_status}}</span> -->
          <div class="me-1">
            <div v-if="getCase(bvcase.id) && getCase(bvcase.id).difficulty"
              :class="'m-0 medal medal-' + getCase(bvcase.id).difficulty.toLowerCase()"></div>
          </div>
          <div v-if="!getCase(bvcase.id)" class="me-1">
            <fa icon="trash" class="text-danger" />
          </div>
          <div class=" me-1">
            <btn size="sm" is-router-link :to="{ name: 'edit_case', params: { case_id: bvcase.id } }">
              <fa icon="chevron-right"></fa>
            </btn>
          </div>
          <button type="button" class="btn-close ms-2 me-n1" aria-label="Close"
            @click="removeCaseFromGroup(bvcase, 'cases')"></button>
        </list-group-item>
      </draggable>
    </card>
    <template #footer-inner>
      <btn class="float-end" btnClass="primary" @click.native="saveCollection(collection)"> SAVE </btn>
    </template>
  </card>
</template>
<script>
import prosemirrorEditor from '~/components/prosemirror-editor.vue'

import { mapActions, mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { findIndex } from 'lodash'
export default {
  components: {
    draggable, prosemirrorEditor
  },

  mounted() {

  },
  computed: {
    ...mapState('editor', ['cases', 'collection', 'loaded']),
    title() {
      if (this.collection) return this.$t('trainingSession.collection', { name: this.collection.name })
      return ''
    },
    collectionsCases() {
      if (this.cases && this.collection && this.collection.cases) {
        var collectionsCaseIds = this.collection.cases.map((c) => c.pivot.case_id)
        return this.cases.filter((c) => {
          return collectionsCaseIds.includes(c.id)
        })
      }
    },
    collectionsReserveCases() {
      if (this.cases && this.collection && this.collection.reserve_cases) {
        var collectionsCaseIds = this.collection.reserve_cases.map((c) => c.pivot.case_id)
        return this.cases.filter((c) => {
          return collectionsCaseIds.includes(c.id)
        })
      }
    }
  },
  methods: {
    ...mapActions('editor', ['getCaseCollection', 'saveCaseCollection', 'update']),
    getCase(id) {
      var bvcase = this.cases.find((c) => id == c.id);
      return bvcase
    },
    saveCollection(collection) {
      this.saveCaseCollection(collection)
        .then(() => {
          this.$router.push({ name: 'collections' })
        })
        .catch(error => {
          this.collectionErrors = error.response.data.errors
        });
    },
    removeCaseFromGroup(bvcase, case_group) {
      var index = findIndex(this.collection[case_group], (bvc) => { return bvcase.id == bvc.id })
      if (index > -1) {
        this.collection[case_group].splice(index, 1)
      }
    },
  },
  data() {
    return {
      collectionErrors: [],
    }
  },
}

</script>
<style>
.list-group-item.ghost {
  border: 2px solid red;
}
</style>
