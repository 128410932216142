import axios from 'axios'
import store from '~/store'
import { cloneDeep, each, find, findIndex } from 'lodash'
// state
export const state = {
    bvcase: { type: null },

    candidates: [],
    candidate: {},
    candidate_case: {},
    candidate_collections: [],
    candidate_audit: [],
    collections: [],
    dataitems: [],

    loadingCandidates: false,
    loadingCandidate: false,
    loadingCandidateCaseData: false,
    pagination: {},
    records: {},
    stage: null,
    training_sessions: [],
    training_session: {},
}

// getters
export const getters = {}

// mutations
export const mutations = {
    GET_CANDIDATES(state, resp) {
        state.candidates = resp.data.data.candidates
        state.pagination = resp.data.meta
        state.training_sessions = resp.data.data.training_sessions
        state.loadingCandidates = false
    },
    GET_CANDIDATE_OVERVIEW(state, resp) {
        state.candidate = resp.data.candidate
        state.collections = resp.data.collections
        state.training_session = resp.data.training_session
        state.loadingCandidate = false
    },
    UPDATE_CANDIDATE(state, data) {
        var index = findIndex(state.candidates, (c) => { return c.id == data.id })
        if (index > -1) state.candidates.splice(index, 1, data)
        state.loaded = true
    },
    REPLACE_CANDIDATE_CASE(state, data) {
        state.candidate_collections = data.candidate_collections
        state.candidate.collections = data.candidate_collections
    },
    CLEAR_CANDIDATE_DATA(state) {
        state.collections = []
    },
    CLEAR_BVCASE_DATA(state) {
        state.bvcase = {}
        // state.collection = {}
        state.records = {}
        state.loaded = false
    },
    SET_BVCASE_DATA(state, data) {
        state.bvcase = data.bvcase
        state.records = data.records
    },
    GET_CANDIDATE_CASE_DATA(state, data) {
        // console.log(data.records)
        state.bvcase = data.bvcase
        state.candidate = data.candidate
        state.candidate_case = data.candidate_case
        state.candidate_collections = data.candidate_collections
        state.collections = data.collections
        state.dataitems = data.dataitems
        state.records = data.records
        state.loadingCandidateCaseData = false
    },
    ASSIGN_CANDIDATE_TO_TRAINING_SESSION(state, data) {
        // console.log('ASSIGN_CANDIDATE_TO_TRAINING_SESSION', data)
    },
    ADD_NEW_CANDIDATE(state, data) {
        // state.candidates.push(data)
        state.loaded = true
    },
    DELETE_CANDIDATE(state, data) {
    },
    UPDATE_CANDIDATE_CASE_DATA(state, data) {
        state.candidate_case = data.candidate_case
        state.candidate_collections = data.candidate_collections
        state.records = data.records
        state.saving = false
        state.loadingCandidateCaseData = false
    },
    UPDATE_STAGE(state, data) {
        state.stage = data
    },
    UPDATE(state, data) {
        each(data, (val, field) => {
            state[field] = val
        })
    },
    UPDATE_RECORD(state, data) {
        // console.log('UPDATE_RECORD', data) 
        state.records[data.field] = data.value
    },
    ARCHIVE_TRAINING_SESSION(state, data) {
        state.candidate = data.candidate
        state.collections = data.collections
        state.training_session = data.training_session
        state.loadingCandidate = false
    }
}


// actions
export const actions = {
    getCandidates({ commit }, request) {
        state.loadingCandidates = true
        axios.get('candidates', request)
            .then((resp) => {
                commit('GET_CANDIDATES', resp)

            })
    },
    getCandidateOverview({ commit }, request) {
        state.loadingCandidate = true
        return axios.get('candidates/' + request.params.candidate_id, request)
            .then((resp) => {
                commit('GET_CANDIDATE_OVERVIEW', resp)
            })
    },
    getLoggedInCandidate({ commit }, request) {
        state.loadingCandidate = true
        axios.get('candidate_data', request)
            .then((resp) => {
                commit('GET_CANDIDATE_OVERVIEW', resp)
            })
    },

    updateCandidate({ commit, state }, params) {
        // console.log('updateCandidate')
        return axios.post('/candidate/updateCandidate', params)
            .then((resp) => {
                commit('UPDATE_CANDIDATE', resp.data)
            })
    },
    getCandidateCaseData({ commit, dispatch, state }, params) {
        state.loadingCandidateCaseData = true
        return axios.get('/candidateCaseData', params)
            .then((resp) => {
                dispatch('lang/setupQuestionnaireLang', resp.data, { root: true })
                commit('editor/GET_CANDIDATE_CASE_DATA', resp.data, { root: true })
                commit('GET_CANDIDATE_CASE_DATA', resp.data)
            });
    },
    saveCandidateCaseData({ commit, state }, data) {
        data = { case_id: data.bvcase.id, records: data.records, initial_assessment: data.initial_assessment }
        state.loadingCandidateCaseData = true
        return axios.post('/candidateCaseData', data)
            .then((resp) => {
                commit('UPDATE_CANDIDATE_CASE_DATA', resp.data)
                commit('editor/UPDATE_CANDIDATE_CASE_DATA', resp.data, { root: true })
            });
    },
    replaceCandidateCase({ commit, state }, params) {
        return axios.post('/replaceCandidateCase', params)
            .then((resp) => {
                commit('REPLACE_CANDIDATE_CASE', resp.data)
            });
    },
    assignCandidateToTrainingSession({ commit, state }, params) {
        return axios.post('/candidate/assignToTrainingSession', params)
            .then((resp) => {
                commit('ASSIGN_CANDIDATE_TO_TRAINING_SESSION', resp.data)
            })
    },
    addNewCandidate({ commit, state }, params) {
        state.loaded = false
        return axios.post('/candidate/new', params)
            .then((resp) => {
                commit('ADD_NEW_CANDIDATE', resp.data)
            });
    },
    deleteCandidate({ commit, state }, params) {
        return axios.post('/candidate/delete', params)
            .then((resp) => {
                commit('DELETE_CANDIDATE', resp.data)
            })
    },
    clearCandidateData({ commit, state }) {
        commit('CLEAR_CANDIDATE_DATA')
    },
    clearBVCaseData({ commit, state }) {
        commit('CLEAR_BVCASE_DATA')
    },
    setBVCaseData({ commit, state }, bvcase) {
        commit('SET_BVCASE_DATA', bvcase)
    },
    update({ commit, state }, data) {
        commit('UPDATE', data)
    },
    updateRecord({ commit, state }, data) {
        commit('UPDATE_RECORD', data)
    },
    updateStage({ commit, state }, data) {
        commit('UPDATE_STAGE', data)
    },
    archiveTrainingSession({ commit, state }, data) {
        state.loadingCandidate = true
        return axios.post('/candidate/archiveCandidatesTrainingSession', data)
            .then((resp) => {
                commit('ARCHIVE_TRAINING_SESSION', resp.data)
            })
    }
}

export const namespaced = true;


