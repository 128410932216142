<template>
  <div class="stage-progress">
  <div class="progress"><div class="progress-bar progress-bar-success" :style="'width: '+totalPercent+'%;'"></div></div>
  <div v-if="bvcase&&candidate_case" class="flex-container">
    <router-link v-for="(tc,i) in stages" :key="i" :to="stageRouteLink(i)">
      <donut
        :width="size(i)"
        :height="size(i)"
        :percent="stagePercent(i)"
        :sections="sectionCount(i)"
        :percent-pass="50">
        
      </donut>
    </router-link>  
  </div>
</div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name:'stage-progress',
  computed: {
    ...mapState('editor', ['bvcase','candidate_case','stage']),
    stages () {
      var self=this;
      var arr = [];
      _.forEach(this.bvcase.training_cases, function(tc, i) {
        if(self.candidate_case.json && self.candidate_case.json[i+1]) tc = _.merge(tc, self.candidate_case.json[i+1])
          arr.push(tc)
      });
      // for(var tc,i in this.bvcase.training_cases){
      //   if(this.candidate_case[this.stage]){
      //     arr.push(_.merge(tc, this.candidate_case[stage]))
      //   }
      // }
      return arr
    },
    totalPercent (){
      var total = 0;
      _.each(this.stages, function (stage, i) { if(stage.percent) total += stage.percent })
      return total  / _.size(this.stages)
    }
  },
  watch: {
    stages (){
      // console.log('percent changed', this.percent)
      this.redraw()
    }
  },
  methods: {
    redraw () {
      // console.log('redraw')
      this.stages
    },
    size (stage) {
      return this.stage == stage + 1 ? 60:40
    },
    stagePercent (stage) {
      if(this.stages[stage].percent)
        return this.stages[stage].percent;
      return null
    },
    sectionCount (stage) {
      return _.size(this.stages[stage].scoredSections)
    },
    stageRouteLink (stage) {
      var route = {
        name: 'candidate_training_case',
        params: {
          case_id: this.$route.params.case_id,
          stage: stage + 1
        }
      }
      return route
    }
  }
}
</script>